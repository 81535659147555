<template>
  <section class="vh-100 overflow-auto" style="background-color: #eee;">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>

    <div class="container-fluid" style="padding-top: 5%;">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img :src="tomatoDroneImage" alt="Logo" class="login-logo img-fluid">
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <!-- 로그인 폼 -->
          <div class="form-outline mb-4">
            <label class="form-label" for="form3Example3">ID</label>
            <input class="form-control form-control-lg" ref="login_id" type="text" placeholder="ID를 입력하세요..."
              v-model="username" />
          </div>

          <div class="form-outline mb-3">
            <label class="form-label" for="form3Example4">비밀번호</label>
            <input class="form-control form-control-lg" ref="Password" type="password" placeholder="비밀번호를 입력하세요..."
              @keyup.enter="login" v-model="password" />
          </div>

          <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
            <p>&copy; 2024 오토메일. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AUTH from "@/api/auth.js";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "LoginView",
  components: {
    LoadingSpinner,
  },
  computed: {
  },
  data() {
    return {
      tomatoDroneImage: "/assets/images/login_back.png",
      tomatoMissonLogo: "/assets/images/SmartFarm_Black.png",
      username: "",
      password: "",
      isLoading: false,
    };
  },
  mounted() { },
  methods: {
    ...mapActions("user", [
      "login",
    ]),
    ...mapMutations("user", ["INIT_USER_AUTH"]),
    async login() {
      const { username, password } = this;
      if (!username || !password) {
        alert("아이디와 비밀번호를 입력하세요!");
        return;
      }

      try {
        const param = {
          username,
          password
        };
        this.isLoading = true;
        const { data } = await AUTH.login(param);
        const loggedIn = true;
        const token = data.accessToken;
        this.INIT_USER_AUTH({ loggedIn, token });
        this.$router.push("/main");
      } catch (error) {
        console.log("error: ", error);
        // alert("정확한 아이디와 비밀번호를 입력하세요!");
        this.$router.push("/");
      }
      this.isLoading = false;
    },
    goSignUp() {
      this.$router.push("/signup");
    }
  }
};
</script>

<style></style>