<template>
    <div :id="id">
        <apexchart type="bar" :height="height" :options="chartOptions" :series="chartSeries"></apexchart>
    </div>
</template>

<script>
import { apexchart } from 'vue3-apexcharts';
import ApexCharts from 'apexcharts';

export default {
    name: "ColumnChart",
    extends: {
        apexchart
    },
    props: {
        id: { type: String, default: "lineChart" },
        height: { type: String },
        chartTitle: { type: String, default: "" }
    },
    computed: {
        // height() { return 'auto'; }
    },
    data() {
        return {
            chartSeries: [
                // {
                //     data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
                // },
            ],
            chartOptions: {
                chart: {
                    // height: 550,
                    type: 'bar',
                    zoom: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                title: {
                    text: this.chartTitle,
                    align: 'left'
                },
                // grid: {
                //     row: {
                //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                //         opacity: 0.5
                //     },
                // },
                // xaxis: {
                //     // categories: [],//[2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
                // },
                yaxis: {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                        color: '#2E93fA'
                    },
                    labels: {
                        style: {
                            colors: '#2E93fA'
                        },
                        formatter: function (val) {
                            return val.toFixed(0);
                        }
                    },
                    title: {
                        text: "",
                        style: {
                            color: '#2E93fA'
                        }
                    },
                    tickAmount: 7,
                    min: 0
                },
                // fill: {
                //     opacity: 1
                // },
                toolbar: {
                    export: {
                        csv: {
                            filename: "Temperature"
                        }
                    }
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    position: 'top'
                }
            },

        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
        },
        updateChart(chartSeries) {
            var chart = new ApexCharts(document.querySelector(`#${this.id}`), this.chartOptions);
            // console.log(chartSeries);
            this.chartSeries = chartSeries;

            chart.updateSeries(chartSeries, true);
            // chart.updateOptions({
            //     series: chartSeries,
            // });
            chart.render();
        }
    }
};
</script>