<template>
  <!-- <div>Main Content</div> -->
  <div class="main-content">
    <header class="py-1 mb-3 border-bottom">
      <div class="container-fluid d-grid gap-3 align-items-center justify-content-end"
        style="grid-template-columns: 1fr 0fr;">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0 dropdown justify-content-end">
            <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img :src="tomatoMissionImage" alt="Mission" class="rounded-circle" style="max-width: 3em;">
            </a>
            <AppLeftSide></AppLeftSide>
          </div>
          <router-link to="/" style="font-size: large; font-weight: bold;" class="mx-5">농업e지 자가 온라인 과정</router-link>
        </div>
        <div class="flex-shrink-0 dropdown justify-content-end">
          <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img :src="tomatoLogoImage" alt="mdo" class="rounded-circle" style="width: 3em;">
          </a>
          <AppLeftSide></AppLeftSide>
        </div>
      </div>
    </header>
    <router-view></router-view>
  </div>
</template>

<script>
import AppLeftSide from "@/components/AppLeftSide.vue";

export default {
  name: "AppMainContent",
  components: {
    AppLeftSide,
  },
  computed: {
  },
  data() {
    return {
      tomatoLogoImage: "/assets/images/tomato_impossible_128.png",
      tomatoMissionImage: "/assets/images/tomato_mission_128.png",
    };
  },
  watch: {
  },
  created() {
  },
  mounted() { },
  methods: {

  }
}
</script>

<style scoped>
div {
  flex: 2;
}

.main-content {
  height: calc(100vh - 100px);
}
</style>