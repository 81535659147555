import AUTH from "../../../src/api/auth.js";

const state = () => ({
  loggedIn: false,
  pageNumber: 0,
  userInfo: {},
  token: "",
});

const getters = {
  isLoggedIn(state) {
    return state.loggedIn;
  },
  getPageNumber(state) {
    return state.pageNumber;
  },
  getToken(state) {
    return state.token;
  },
};

const actions = {
  async login({ commit }, param) {
    try {
      const result = await AUTH.login(param);
      const loggedIn = true;
      const token = result.accessToken;
      commit("INIT_USER_AUTH", { loggedIn, token });
      return result;
    } catch ({ data }) {
      return data;
    }
  },
  async logout({ commit }) {
    try {
      const { data } = await AUTH.logout();
      return data;
    } catch ({ data }) {
      return data;
    } finally {
      //api 성공 여부와 무관하게 logout 실행
      commit("INIT_USER_AUTH", {
        loggedIn: false,
        token: ""
      });
    }
  },
};

const mutations = {
  INIT_USER_AUTH(state, { loggedIn, token }) {
    state.loggedIn = loggedIn;
    state.token = token;
    localStorage.setItem("loggedIn", loggedIn);
    localStorage.setItem("token", token);
  },
  SET_LOGGED_IN(state, loggedIn) {
    state.loggedIn = loggedIn;
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};