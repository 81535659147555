import { axiosService } from "./index.js";

function getApiPath(endPoint = "") {
  const commonPath = "/api/teacher";
  if (endPoint != "") endPoint = `/${endPoint}`;
  return `${commonPath}${endPoint}`;
}

function getList(params) {
  return axiosService.get(getApiPath("list"), { params: params });
}

function getDownload(params) {
  axiosService.get(getApiPath('download'), {
    responseType: 'blob',
    params: params
  }).then((response) => {
    const url
      = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
    const link = document.createElement('a');
    const now = new Date();
    const timestamp = now.getFullYear() + '-' +
                      ("0" + (now.getMonth() + 1)).slice(-2) + '-' +
                      ("0" + now.getDate()).slice(-2) + '_' +
                      ("0" + now.getHours()).slice(-2) + '-' +
                      ("0" + now.getMinutes()).slice(-2) + '-' +
                      ("0" + now.getSeconds()).slice(-2);
    link.href = url;
    link.setAttribute('download', `Teacher_${timestamp}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}

function check(params) {
  return axiosService.get(getApiPath("check"), { params: params });
}

function getNumber() {
  return axiosService.get(getApiPath("number"), { params: {} });
}

function update(params) {
  return axiosService.post(getApiPath("update"), params);
}

function deleteData(params) {
  return axiosService.post(getApiPath("deleteData"), params);
}

function uploadFile(formData) {
  // const token = localStorage.getItem('token'); // 인증 토큰을 저장소에서 가져온다고 가정
  // console.log('Authorization Token:', token);
  return axiosService.post(getApiPath('upload'), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Authorization': `Bearer ${token}` // Authorization 헤더에 토큰 추가
    }
  });
}

export default { getList, getDownload, update, deleteData, uploadFile, getNumber, check };