<template>
  <ul class="dropdown-menu text-small shadow">
    <li v-for="menu in menu_items" :key="menu.id">
      <router-link :to="menu.link" class="dropdown-item">{{ menu.title }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "AppLeftSide",
  components: {},
  data() {
    return {
      menu_items: [
        {
          id: 0,
          link: "/main",
          view: "MainView",
          title: "메인",
        },
        {
          id: 1,
          link: "/teacher",
          view: "TeacherView",
          title: "농업이지쌤 관리",
        },
        {
          id: 2,
          link: "/student",
          view: "StudentView",
          title: "교육 현황",
        },
        {
          id: 3,
          link: "/settle",
          view: "SettleView",
          title: "지급 현황",
        },
        /*
        {
          id: 1,
          link: "/screen",
          view: "ScreenView",
          title: "온실 창문",
        },
        {
          id: 2,
          link: "/eco",
          view: "EcoView",
          title: "온실앞 기상대"
        },
        {
          id: 3,
          link: "/sensor",
          view: "SensorView",
          title: "온실 센서"
        },
        {
          id: 4,
          link: "/onoff",
          view: "OnoffView",
          title: "장치 제어 히스토리"
        },
        {
          id: 5,
          link: "/kmaWeather",
          view: "KmaWeatherView",
          title: "기상청 예보"
        },
        */
        {
          id: 10,
          link: "/logout",
          title: "Logout"
        },
      ]
    }
  },
  created() {
  },
  mounted() { },
  methods: {
  }
}
</script>

<style scoped></style>