import { createRouter, createWebHistory } from "vue-router"; //
// import store from "@/store";

import LoginView from "@/views/LoginView.vue";
import MainView from "@/views/MainView.vue";
import SignupView from "@/views/SignupView.vue";

import ScreenView from "@/views/ScreenView.vue";
import EcoView from "@/views/EcoView.vue";
import SensorView from "@/views/SensorView.vue";
import OnoffView from "@/views/OnoffView.vue";
import KmaWeatherView from "@/views/KmaWeatherView.vue";

import TeacherView from "@/views/TeacherView.vue";
import StudentView from "@/views/StudentView.vue";

import LinkView from "@/views/LinkView.vue";
import LinkView2 from "@/views/LinkView2.vue";
import LinkView3 from "@/views/LinkView3.vue";
import LinkView4 from "@/views/LinkView4.vue";
import LinkView5 from "@/views/LinkView5.vue";
import LinkView6 from "@/views/LinkView6.vue";
import LinkView7 from "@/views/LinkView7.vue";
import LinkView8 from "@/views/LinkView8.vue";

const router = createRouter({
  history: createWebHistory(), //import.meta.env.BASE_URL
  routes: [
    {
      path: "/",
      redirect: "/link",
    },
    {
      path: "/link",
      name: "LinkView",
      component: LinkView,
    },
    {
      path: "/link2",
      name: "LinkView2",
      component: LinkView2,
    },
    {
      path: "/link3",
      name: "LinkView3",
      component: LinkView3,
    },
    {
      path: "/link4",
      name: "LinkView4",
      component: LinkView4,
    },
    {
      path: "/link5",
      name: "LinkView5",
      component: LinkView5,
    },
    {
      path: "/link6",
      name: "LinkView6",
      component: LinkView6,
    },
    {
      path: "/link7",
      name: "LinkView7",
      component: LinkView7,
    },
    {
      path: "/link8",
      name: "LinkView8",
      component: LinkView8,
    },
    {
      path: "/login",
      name: "LoginView",
      component: LoginView,
      beforeEnter(to, from, next) {
        if (JSON.parse(localStorage.getItem("loggedIn"))) {
          next("/main");
        } else {
          removeLocalStorage();
          next();
        }
      },
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter(to, from, next) {
        removeLocalStorage();
        if (from.meta.requiresAuth) {
          return next('/');
        }
        location.reload();
      },
    },
    {
      path: "/signup",
      name: "SignupView",
      component: SignupView,
      beforeEnter,
    },
    {
      path: "/main",
      name: "MainView",
      component: MainView,
      beforeEnter,
    },
    {
      path: "/screen",
      name: "ScreenView",
      component: ScreenView,
      beforeEnter,
    },
    {
      path: "/eco",
      name: "EcoView",
      component: EcoView,
      beforeEnter,
    },
    {
      path: "/sensor",
      name: "SensorView",
      component: SensorView,
      beforeEnter,
    },
    {
      path: "/onoff",
      name: "OnoffView",
      component: OnoffView,
      beforeEnter,
    },
    {
      path: "/kmaWeather",
      name: "KmaWeatherView",
      component: KmaWeatherView,
      beforeEnter,
    },

    {
      path: "/teacher",
      name: "TeacherView",
      component: TeacherView,
      beforeEnter,
    },
    {
      path: "/student",
      name: "StudentView",
      component: StudentView,
      beforeEnter,
    },

  ],
});

function beforeEnter(to, from, next) {
  if (
    // store.getters["user/isLoggedIn"] !== undefined &&
    JSON.parse(localStorage.getItem("loggedIn"))
  ) {
    next();
  } else {
    localStorage.setItem("loggedIn", false);
    next("/");
  }
}

function removeLocalStorage() {
  let localItems = Object.keys(localStorage);
  for (let k of localItems) {
    if (k !== "rememberId") {
      localStorage.removeItem(k);
    }
  }
}

export default router;
