<template>
  <section class="vh-100 overflow-auto" style="height: 100%">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>

    <!-- 상단 배너 -->
    <div class="header d-flex justify-content-between align-items-center py-3">
      <button class="btn btn-link back-btn" @click="goBackward">
        <font-awesome-icon icon="fa fa-arrow-left" />
      </button>
      <div class="logo-box">
        <img :src="ezlogo" class="header-logo" alt="농업e지 로고" />
      </div>
    </div>
    <!-- 인포 라벨 -->
    <div class="step-label py-3 text-left">
      <h1>Step 2. 농업e지 이용안내</h1>
    </div>
    <!-- 하위 라벨 -->
    <div class="stepunder-label py-3 text-left">
      <h2>Step 2-1. 농업경영체 등록·변경등록</h2>
    </div>
    <!-- 첫 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">01</p>
          <p class="fs-2">
            <strong>로그인</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            원활한 서비스 이용을 위해 로그인이 필요해요.
            <br />[개인] 또는 [법인] 버튼을 누른 뒤
            <br />안내에 따라 인증을 진행해주세요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_1" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 두 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">02</p>
          <p class="fs-2">
            <strong>농업경영체 정보</strong>
            <br /><strong>상세 내용 확인</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            [마이페이지] - [농업경영체 정보]에서
            <br />내 농업경영체의 최신 정보를 볼 수 있어요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_2" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 세 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">03</p>
          <p class="fs-2">
            <strong>농업경영체 정보 변경등록</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            농업경영체 정보 변경이 필요한 경우,
            <br />[농업경영체 정보] 메뉴에서 상단 [변경등록]
            <br />버튼을 눌러 나오는 절차를 진행해주세요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_3" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 네 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">04</p>
          <p class="fs-2">
            <strong>농업경영체 정보 변경등록</strong>
            <br /><strong>처리결과 확인</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            변경등록 신청을 완료했다면, 해당 내역과 현재 처리상태를
            <br />[농업e지 원패스] - [농업경영체 정보] - [농업경영체 신청내역]
            <br />메뉴에서 볼 수 있어요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_4" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 다음으로 -->
    <div class="text-left mx-auto d-grid mb-4">
      <div class="text-left mt-4">
        <button class="btn btn-primary btn-lg full-width-btn" @click="goForward">
          <span class="thin-text">다음으로</span><br>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "LinkView3",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      ezlogo: "/assets/images/ez_logo.png",
      ezstep2_1: "/assets/images/ez_step2_1.png",
      ezstep2_2: "/assets/images/ez_step2_2.png",
      ezstep2_3: "/assets/images/ez_step2_3.png",
      ezstep2_4: "/assets/images/ez_step2_4.png",
    };
  },
  mounted() { },
  methods: {
    goBackward() {
      this.isLoading = true;
      this.$router.push("/link2");
    },
    goForward() {
      this.isLoading = true;
      this.$router.push("/link4");
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.back-btn {
  font-size: 1.5rem;
  color: #505050;
  border: none;
  cursor: pointer;
}

.header-logo {
  width: 100px;
  height: auto;
}

.step-label {
  background-color: #f95a25;
  color: white;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.stepunder-label {
  background-color: #eaeaea;
  color: black;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  font-size: 1rem !important;
}

.step-label h1 {
  font-size: 1.2rem;
  margin: 0;
}

.stepunder-label h2 {
  font-size: 1.1rem;
}

.text-center p {
  color: #6c757d;
}

.text-left {
  width: 100%;
  text-align: left;
  margin: 0;  
}

.text-left p {
  text-align: left !important;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  color: #000000;
  font-size: 1rem;
}

.fs-1 {
  font-size: 0.9rem !important;
  color: #f95a25 !important;
}

.fs-2 {
  font-size: 1rem;
  margin-top: 20px;
}

strong {
  color: #000000;
  font-weight: bold;
}

.center-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 40px auto;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
  max-width: 640px;
  min-height: 420px;
  box-sizing: border-box;
}

.image-container {
  text-align: center;
  margin-top: 20px;
  max-width: 450px;
  width: auto;
  height: auto;
}

.content {
  width: 100%;
}

.full-width-btn {
    background-color: #2c387e;
    border: none;
    font-size: 1rem;
    padding: 15px 30px;
    text-align: center;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto; 
    position: relative;
}
</style>