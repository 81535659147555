<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-md">
				<div class="fs-2 fw-bold"> 메인 대시보드 </div>
			</div>
			<div class="col-md">
				<div class="row justify-content-end">
					<div class="col text-end">
						<div class="input-group mb-3">
							<input type="date" class="form-control" placeholder="검색 시작일" aria-label="start_dt"
								v-model="startDt" />
							<span class="input-group-text">~</span>
							<input type="date" class="form-control" placeholder="검색 종료일" aria-label="end_dt"
								v-model="endDt" />
							<button type="button" class="btn btn-primary" @click="getDatas">검색</button>
							<button type="button" class="btn btn-outline-secondary" @click="resetDatas"><font-awesome-icon
									icon="fa-solid fa-eraser" /></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<LoadingSpinner v-if="isLoading"></LoadingSpinner>
			<!-- <GridTemplate ref="grid" :columns="columnDefs" :dataSource="dataSource"></GridTemplate> -->

			<div class="row" style="height:300px;">
				<div class="col">
					<LineChart ref="chart" id="lineChart" :height="'300px'"></LineChart>
				</div>
			</div>
			<div class="row" style="height:250px;">
				<div class="col-4">
					<ColumnChart ref="pllnltChart" id="pllnltChart" :height="'250px'"></ColumnChart>
				</div>
				<div class="col-4">
					<ColumnChart ref="frtstcoChart" id="frtstcoChart" :height="'250px'"></ColumnChart>
				</div>
				<div class="col-4">
					<ColumnChart ref="outtrnChart" id="outtrnChart" :height="'250px'"></ColumnChart>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import HOUSE from "@/api/house.js";
import { KMA_WEATHER_COLUMN } from "@/plugins/grid-column.js";
import Papa from 'papaparse';

export default {
	name: "MainView",
	components: { LoadingSpinner },
	data() {
		return {
			gridApi: null,
			columnDefs: KMA_WEATHER_COLUMN,
			dataSource: [],
			defaultColDef: {
				// flex: 1,
				resizable: true,
				filter: 'agTextColumnFilter',
				menuTabs: ['filterMenuTab'],
			},
			startDt: '',
			endDt: '',
			isLoading: false,
		}
	},
	created() {
		// 오늘 날짜로 셋팅
		let startDate = new Date();
		const offsetToday = startDate.getTimezoneOffset();
		startDate = new Date(startDate.getTime() - (offsetToday * 60 * 1000));
		startDate.setDate(startDate.getDate() - 10);

		let endDate = new Date();
		const offsetEnd = endDate.getTimezoneOffset();
		endDate = new Date(endDate.getTime() - (offsetEnd * 60 * 1000));

		this.startDt = startDate.toISOString().split('T')[0];
		this.endDt = endDate.toISOString().split('T')[0];

		this.getDatas();

		this.getSangCSVData();
	},
	watch: {
	},
	mounted() {
	},
	methods: {
		onGridReady(params) {
			this.gridApi = params.api;
		},
		onBtnExport() {
			this.gridApi.exportDataAsCsv();
		},
		async getDatas() {
			try {
				let { startDt, endDt } = this;
				if (startDt) {
					startDt = startDt + 'T00:00:00';
				}
				if (endDt) {
					endDt = endDt + 'T23:59:59';
				}
				const param = {
					start_dt: startDt,
					end_dt: endDt,
				};
				console.debug('param: ', param);
				this.isLoading = true;
				const { data } = await HOUSE.getSensorsList(param);
				let dataSource = data.sort((a, b) => {
					return new Date(a.meaDat) - new Date(b.meaDat);
				});
				// console.log('dataSource: ', dataSource);
				this.dataSource = dataSource;

				// TODO: 일별 통계데이터 계산
				// 온실 센서 -> 실내온도(temp-평균), 실내습도(hum-중앙값), CO2(co2-중앙값), 누적일사량(radS-평균)
				// <날짜, <'실내온도 temp', {'총합','평균','중앙값', 최소값, 최대값}>>
				const COL_TEMP = 'temp';
				const COL_HUM = 'hum';
				const COL_CO2 = 'co2';
				const COL_RADS = 'radS';

				const STAT_SUM = 'sum';
				const STAT_AVG = 'average';
				const STAT_MEDIAN = 'median';

				const result = dataSource.reduce((acc, entry) => {
					const timestamp = new Date(entry.meaDat + 'Z');
					const dateKey = timestamp.toISOString().split('T')[0];

					if (!acc.has(dateKey)) {
						acc.set(dateKey, {
							[COL_TEMP]: { values: [], [STAT_SUM]: 0, [STAT_AVG]: 0, min: 0, max: 0, [STAT_MEDIAN]: 0 },
							[COL_HUM]: { values: [], [STAT_SUM]: 0, [STAT_AVG]: 0, min: 0, max: 0, [STAT_MEDIAN]: 0 },
							[COL_CO2]: { values: [], [STAT_SUM]: 0, [STAT_AVG]: 0, min: 0, max: 0, [STAT_MEDIAN]: 0 },
							[COL_RADS]: { values: [], [STAT_SUM]: 0, [STAT_AVG]: 0, min: 0, max: 0, [STAT_MEDIAN]: 0 },
						});
					}
					const dateEntry = acc.get(dateKey);

					// 통계값을 계산하고 업데이트하는 함수
					const updateStatistics = (values, category) => {
						values.push(entry[category]);
						const statistics = this.calculateStatistics(values);
						Object.assign(dateEntry[category], statistics);
					};
					// 온도와 습도에 대한 통계값 계산 및 업데이트
					updateStatistics(dateEntry.temp.values, 'temp');
					updateStatistics(dateEntry.hum.values, 'hum');
					updateStatistics(dateEntry.co2.values, 'co2');
					updateStatistics(dateEntry.radS.values, 'radS');

					return acc;
				}, new Map());
				// console.log("result : ", result);

				// Chart Series 데이터 생성
				let chartSeries = [];
				const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'];
				const COLOR_TEMP = colors[0];
				const COLOR_HUM = colors[1];
				const COLOR_CO2 = colors[2];
				const COLOR_RADS = colors[3];

				const getSeriesData = (result, col, stat) =>
					Array.from(result, ([date, entry]) => ({
						x: date,
						y: entry[col][stat]
					}));

				chartSeries.push({ name: '온도(평균)', data: getSeriesData(result, COL_TEMP, STAT_AVG), color: COLOR_TEMP });
				// chartSeries.push({ name: '온도(중앙값)', data: getSeriesData(result, COL_TEMP, STAT_MEDIAN), color: COLOR_TEMP });
				// chartSeries.push({ name: '습도(평균)', data: getSeriesData(result, COL_HUM, STAT_AVG), color: COLOR_HUM });
				chartSeries.push({ name: '습도(중앙값)', data: getSeriesData(result, COL_HUM, STAT_MEDIAN), color: COLOR_HUM });
				// chartSeries.push({ name: 'CO2(평균)', data: getSeriesData(result, COL_CO2, STAT_AVG), color: COLOR_CO2 });
				chartSeries.push({ name: 'CO2(중앙값)', data: getSeriesData(result, COL_CO2, STAT_MEDIAN), color: COLOR_CO2 });
				chartSeries.push({ name: '누적일사량(평균)', data: getSeriesData(result, COL_RADS, STAT_AVG), color: COLOR_RADS });
				// chartSeries.push({ name: '누적일사량(중앙값)', data: getSeriesData(result, COL_RADS, STAT_MEDIAN), color: COLOR_RADS });
				console.log(chartSeries);
				this.$refs.chart.updateChart(chartSeries);

				// 초장, 열매수, 수확수 ?? -> sang.csv 파일 데이터로 보여줘야함.
			} catch (error) {
				alert(error.message);
				console.log("error: ", error.message);
			}
			this.isLoading = false;
		},
		resetDatas() {
			this.startDt = '';
			this.endDt = '';
		},
		calculateStatistics(values) {
			const sumArr = values.reduce((acc, val) => acc + val, 0);
			if (sumArr == 0) {
				return { sum: 0, average: 0, min: 0, max: 0, median: 0 };
			}
			const sum = sumArr.toFixed(2);
			const average = Number(sum / values.length).toFixed(2);
			const min = Math.min(...values);
			const max = Math.max(...values);

			// median 값 구하기
			const sortedArr = values.slice().sort((a, b) => a - b);
			const middleIdx = Math.floor(sortedArr.length / 2);
			const medianResult = sortedArr.length % 2 === 0
				? (sortedArr[middleIdx - 1] + sortedArr[middleIdx]) / 2
				: sortedArr[middleIdx];
			const median = medianResult.toFixed(2);
			return { sum, average, min, max, median };
		},
		async getSangCSVData() {
			// 초장(pllnLt), 열매수(frtstCo), 수확수(outtrn) ?? -> sang.csv 파일 데이터로 보여줘야함.
			let sangCsv = "/assets/sang/sang.csv";
			let resultData = await new Promise((resolve,) => {
				Papa.parse(sangCsv, {
					download: true,
					delimiter: ",",
					// encoding: "UTF-8",
					complete: function (results) {
						// const sangData = results.data;
						resolve(results.data);
					},
				});
			})
			this.setSangCSVData(resultData);
		},
		setSangCSVData(sangData) {
			// 0번째 row는 header -> [1]=초장(pllnLt), [11]=열매수(frtstCo), [13]=수확수(outtrn), [14]=날짜(frmdate)(2024-01-26T00:00:00+09:00)
			// ["farm_cde","pllnLt","grwtLt","lefCunt","lefLt","lefBt","stemThck","fcluHg","flanGrupp","frtstGrupp","hvstGrupp","frtstCo","hvstCo","outtrn","frmdate"]
			const COL_PLLNLT = "pllnLt";
			const COL_FRSTSTCO = "frtstCo";
			const COL_OUTTRN = "outtrn";

			const groupedData = {};
			for (let i = 1; i < sangData.length; i++) {
				// const timestamp = new Date(sangData[i][14] + 'Z');
				// const dateKey = timestamp.toISOString().split('T')[0];
				const dateKey = new Date(sangData[i][14]).toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" })
				const pllnLt = sangData[i][1];
				const frtstCo = sangData[i][11];
				const outtrn = sangData[i][13];

				if (!groupedData[dateKey]) {
					groupedData[dateKey] = {
						[COL_PLLNLT]: 0,
						[COL_FRSTSTCO]: 0,
						[COL_OUTTRN]: 0,
						count: 0,
					};
				}

				groupedData[dateKey][COL_PLLNLT] += parseFloat(pllnLt);
				groupedData[dateKey][COL_FRSTSTCO] += parseFloat(frtstCo);
				groupedData[dateKey][COL_OUTTRN] += parseFloat(outtrn);
				groupedData[dateKey].count++;
			}

			const dataItems = [];
			for (const dateKey in groupedData) {
				if (Object.hasOwn(groupedData, dateKey)) {
					const sumValue = groupedData[dateKey];
					const count = sumValue.count;
					const avg_pllnLt = sumValue[COL_PLLNLT] / count;
					const avg_frtstCo = sumValue[COL_FRSTSTCO] / count;
					const avg_outtrn = sumValue[COL_OUTTRN] / count;

					const item = {
						date: dateKey,
						[COL_PLLNLT]: avg_pllnLt,
						[COL_FRSTSTCO]: avg_frtstCo,
						[COL_OUTTRN]: avg_outtrn,
					};
					dataItems.push(item);
				}
			}
			// console.log(dataItems);

			const getSeriesData = (list, col) => {
				list.sort((a, b) => { // 날짜 오름차순으로 정렬
					const dateA = a.date.split('-').join('');
					const dateB = b.date.split('-').join('');
					return dateA - dateB;
				});
				return list.map(item => ({ x: item.date, y: item[col].toFixed(3) }))
			};

			// Chart Series 데이터 생성
			const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'];
			const COLOR_PLLNLT = colors[0];
			const COLOR_FRSTSTCO = colors[1];
			const COLOR_OUTTRN = colors[2];

			const pllnltChartSeries = [{ name: '초장', data: getSeriesData(dataItems, COL_PLLNLT), color: COLOR_PLLNLT }];
			const frststcoChartSeries = [{ name: '열매수', data: getSeriesData(dataItems, COL_FRSTSTCO), color: COLOR_FRSTSTCO }];
			const outtrnChartSeries = [{ name: '수확수', data: getSeriesData(dataItems, COL_OUTTRN), color: COLOR_OUTTRN }];
			console.log(pllnltChartSeries);
			console.log(frststcoChartSeries);
			console.log(outtrnChartSeries);

			this.$refs.pllnltChart.updateChart(pllnltChartSeries);
			this.$refs.frtstcoChart.updateChart(frststcoChartSeries);
			this.$refs.outtrnChart.updateChart(outtrnChartSeries);
		},
	}
}
</script>

<style></style>
