<template>
  <section class="vh-100 overflow-auto" style="height: 100%">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>

    <!-- 상단 배너 -->
    <div class="header d-flex justify-content-between align-items-center py-3">
      <button class="btn btn-link back-btn" @click="goBackward">
        <font-awesome-icon icon="fa fa-arrow-left" />
      </button>
      <div class="logo-box">
        <img :src="ezlogo" class="header-logo" alt="농업e지 로고" />
      </div>
    </div>
    <!-- 인포 라벨 -->
    <div class="step-label py-3 text-left">
      <h1>Step 1. 농업e지란?</h1>
    </div>
    <!-- 첫 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="content">
          <div class="text-section">
            <p class="fs-1 text-muted">왜 만들었을까?</p>
            <p class="fs-2">
              농식품사업 신청 서식을 <strong>표준화하고</strong>
              <br />업무처리 절차는
              <br /><strong>간소화하기 위해서예요.</strong>
            </p>
            <p class="fw-light" style="font-size: 1rem;">
              기획·신청·평가·환류 등의 모든 절차를
              <br />온라인화하기 위해서예요.
              <br />나아가 농업보조금의 투명성과 효율성을
              <br />제고하여 과학농정 추진 기반을 조성할 수 있어요.
            </p>
          </div>
          <div class="image-container">
            <img :src="ezgraph" alt="EZ Graph" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 두 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-section">
          <p class="fs-1 text-muted">왜 필요할까?</p>
          <p class="fs-2">
            통합관리시스템은
            <br /><strong>농업인 만족도를 제고</strong>하고,
            <br /><strong>농식품사업의 효율적으로</strong>
            <br /><strong>관리 강화</strong>하기 위해 필요해요.
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            농업인에게는,
            <br />농식품사업에 관한 정보를 신속하고 체계적으로
            <br />제공하여 농식품사업에 대한 이해도 및 만족도를
            <br />제고할 수 있어요.
            <br />
            <br />사업담당자는,
            <br />사업진행상황을 실시간으로 점검하고
            <br />농식품사업간 시너지 효과를 창출해
            <br />재정 성과를 확산할 수 있어요.
          </p>
        </div>
        <div class="image-container">
          <img :src="ezpay" alt="EZ Pay" class="img-fluid" />
        </div>
      </div>
    </div>
    <!-- 세 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-section">
          <p class="fs-1 text-muted">어떤 효과를 기대할 수 있을까?</p>
          <p class="fs-2">
            재정사업의 <strong>효율성을 제고하고,</strong>
            <br /><strong>예산을 절감해요.</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            농업경영체 정보 활용을 포함한 보조·융자사업
            <br />관리체계를 개선해요.
            <br />
            <br />이뿐만 아니라 상시 모니터링 체계 구축으로
            <br />농업경영체 보조금 부정, 중복 수급을 방지하고,
            <br />편중지원을 예방하는 등
            <br />보조금 관리의 투명성을 향상할 수 있어요.
          </p>
        </div>
        <div class="image-container">
          <img :src="ezcoin" alt="EZ Coin" class="img-fluid" />
        </div>
      </div>
    </div>
    <!-- 네 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-section">
          <p class="fs-1 text-muted">어떻게 운영하고 있을까?</p>
          <p class="fs-2">
            2021년 기준으로,
            <br /><strong>178만 </strong>농업경영체와
            <br /><strong>220만 </strong>농업인이 등록됐어요.
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            132개 농식품사업시행지침서를 관리하고
            <br />기본형 공익직불 등 농업보조사업 관리시스템을
            <br />구축, 운영하고 있어요.
          </p>
        </div>
        <div class="image-container">
          <img :src="ezperson" alt="EZ Person" class="img-fluid" />
        </div>
      </div>
    </div>
    <!-- 다음으로 -->
    <div class="text-left mx-auto d-grid mb-4">
      <div class="text-left mt-4">
        <button class="btn btn-primary btn-lg full-width-btn" @click="goForward">
          <span class="thin-text">다음으로</span><br>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "LinkView2",
  components: {
    LoadingSpinner,
  },
  computed: {
  },
  data() {
    return {
      isLoading: false,
      ezlogo: "/assets/images/ez_logo.png",
      ezgraph: "/assets/images/ez_graph1.png",
      ezpay: "/assets/images/ez_pay1.png",
      ezcoin: "/assets/images/ez_coin1.png",
      ezperson: "/assets/images/ez_person1.png",

    };
  },
  mounted() { },
  methods: {
    goBackward() {
      this.isLoading = true;
      this.$router.push("/link");
    },
    goForward() {
      this.isLoading = true;
      this.$router.push("/link3");
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.back-btn {
  font-size: 1.5rem;
  color: #505050;
  border: none;
  cursor: pointer;
}

.header-logo {
  width: 100px;
  height: auto;
}

.step-label {
  background-color: #f95a25;
  color: white;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.step-label h1 {
  font-size: 1.2rem;
  margin: 0;
}

.text-section {
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
}

.text-left {
  width: 100%;
  text-align: left;
}

.text-left p {
  text-align: left !important;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  color: #000000;
  font-size: 1rem;
}

.fs-1 {
  font-size: 0.9rem !important;
  color: #f95a25 !important;
}

.fs-2 {
  font-size: 1rem;
  margin-top: 20px;
}

strong {
  color: #000000;
  font-weight: bold;
}

.center-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 40px auto;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
  max-width: 640px;
  min-height: 420px;
  box-sizing: border-box;
}

.container-fluid {
  padding-top: 0%;
}

.content {
  width: 100%;
}

.content:first-child {
  margin-top: 0 !important;
}

.image-container {
  text-align: center;
  margin-top: 20px;
  max-width: 450px;
  width: auto;
  height: auto;
}

.full-width-btn {
    background-color: #2c387e;
    border: none;
    font-size: 1rem;
    padding: 15px 30px;
    text-align: center;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto; 
    position: relative;
}
</style>