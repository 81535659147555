<template>
    <div class="signup-page">
        <div class="signup-outer-container">
            <div class="row">
                <div class="signup-container">
                    <img :src="tomatoDroneImage" alt="Logo" class="signup-logo">
                </div>
                <div class="signup-form-data">
                    <!-- <div class="close-btn" @click="closeSignup"
                        style="position: absolute; top: 5px; right:5px; cursor:pointer">X
                    </div> -->
                    <div class="logo-text">
                        회원가입
                    </div>

                    <div class="row signup-input-label">
                        Username
                    </div>
                    <div class="row signup-group">
                        <input ref="signup_id" type="text" placeholder="Enter Your Username..." v-model="username" />
                    </div>

                    <div class="row signup-input-label">
                        Password
                    </div>
                    <div class="row signup-group">
                        <input ref="signup_pw" type="password" placeholder="Enter Your Password..." v-model="password" />
                    </div>

                    <div class="row signup-input-label">
                        Email
                    </div>
                    <div class="row signup-group">
                        <input ref="signup_email" type="email" placeholder="Enter Your Email..." v-model="email" />
                    </div>

                    <div class="row signup-input-label">
                        Role
                    </div>
                    <div class="row signup-group" style="display: flex; padding-top: 10px; padding-right: 20px;">
                        <input type="checkbox" id="role_mod" v-model="role_mod" style="margin: 0px;">
                        <label for="role_mod">MOD</label>

                        <input type="checkbox" id="role_user" v-model="role_user" style="margin: 0px;">
                        <label for="role_user">USER</label>

                        <input type="checkbox" id="role_admin" v-model="role_admin" style="margin: 0px;">
                        <label for="role_admin">ADMIN</label>
                    </div>

                    <div class="signup-group-submit">
                        <button type="submit" class="submit-btn" @click="signup">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AUTH from "@/api/auth.js";

export default {
    name: "SignupView",
    components: {
    },
    computed: {
    },
    data() {
        return {
            tomatoDroneImage: "/assets/images/tomato_character2_drone.png",
            tomatoMissonLogo: "/assets/images/SmartFarm_Black.png",
            username: "",
            password: "",
            email: "",

            role_mod: true,
            role_user: true,
            role_admin: true,
        };
    },
    mounted() { },
    methods: {
        async signup() {
            try {
                const { username, password, email } = this;
                if (!username || !password) {
                    alert("아이디와 비밀번호를 입력하세요!");
                    return;
                } 8
                let role = [];
                if (this.role_mod) role.push("mod");
                if (this.role_user) role.push("user");
                if (this.role_admin) role.push("admin");

                const param = {
                    username,
                    password,
                    email,
                    role
                };
                const { data } = await AUTH.signup(param);
                console.log(data);
                alert("회원가입 성공!");
                this.$router.push("/");
            } catch (error) {
                console.log("error: ", error.data.message);
                alert(error.data.message);
                // this.$router.push("/");
            }
        },
        closeSignup() {
            this.$router.push("/");
        }
    }
};
</script>

<style>
* {
    box-sizing: border-box;
    font-family: Verdana, sans-serif;
}

.signup-page {
    margin: 0;
    background-color: #d5d5d5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.signup-outer-container {
    display: table;
    table-layout: fixed;
    width: 1000px !important;
}

.signup-container {
    display: table-cell;
    width: 60% !important;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
}

.signup-logo {
    width: 100% !important;
    height: 100%;
    border-radius: 5%;
}

.logo-text {
    text-align: center;
    font-family: Noto Serif;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 25px;
}

.signup-group {
    font-size: 1em;
    padding: 8px 20px 4px 20px;
}

.signup-form-data {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: #b22300;
    border-radius: 5%;
    box-shadow: 0px 0px 30px 0px #666;
    color: #ecf0f1;
}

.signup-input-label {
    float: left;
    padding-left: 20px;
}

.signup-group input {
    background-color: white;
    padding: 0.3rem 0.5rem 0.4rem 0.5rem !important;
    margin: 0.2rem 1rem 0.2rem 0.1rem;
    border: 1px solid #ced4da !important;
    width: 95%;
    transform: translateX(-5px);
    border-radius: 8px;
    font-size: 0.8em;
    color: white;
}

.user-icon {
    font-size: 1.3em;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 8px;
}

.signup-form-data label,
.signup-form-data input {
    width: 100%;
}

.signup-form-data label {
    color: white;
    font-size: 1em;
}

.signup-form-data input {
    background-color: white;
    border: none;
    color: black;
    font-size: 0.8em;
    margin: 5px 0px 10px;
}

.signup-form-data ::placeholder {
    color: black;
    opacity: 1;
}

.signup-form-data button {
    background-color: #ffffff;
    cursor: pointer;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    transition: background-color 0.2s ease-in-out;
    width: 90%;
}

.signup-form-data button:hover {
    background-color: #eeeeee;
}

.signup-group-submit {
    padding-top: 10px;
}

.submit-btn {
    border-radius: 8px;
}

@media screen and (max-width: 600px) {
    .signup-outer-container {
        align-items: unset;
        background-color: unset;
        display: unset;
        justify-content: unset;
    }

    .signup-outer-container .signup-container {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
    }

    .signup-outer-container .signup-form-data {
        border-radius: unset;
        box-shadow: unset;
        width: 100%;
    }

    .signup-outer-container .signup-form-data form {
        margin: 0 auto;
        max-width: 280px;
        width: 100%;
    }
}
</style>