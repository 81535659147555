<template>
  <div class="container-fluid" style="display: block;">
    <div class="row">

      <!-- 제목과 추가 버튼 -->
      <div class="col-md d-flex justify-content-between align-items-center">
        <div class="col text-start">
          <div class="fs-2 fw-bold"> 농업이지쌤 </div>
        </div>
        <div class="col text-end">
          <div class="input-group mb-3">
            <input type="file" class="form-control" placeholder="검색" aria-label="upload-file" ref="fileInput" />
            <button type="button" class="btn btn-primary" @click="uploadFile">
              <font-awesome-icon icon="fa-solid fa-upload" />
              <!-- 업로드 -->
            </button>
            <button type="button" class="btn btn-outline-secondary" @click="showExcel">
              <font-awesome-icon icon="fa-solid fa-file-excel" />
              <!-- Excel -->
            </button>
            <button type="button" class="btn btn-outline-secondary" @click="openAddModal">
              <font-awesome-icon icon="fa-solid fa-user-plus" />
              <!-- 이지쌤 추가 -->
            </button>
          </div>
        </div>
      </div>

      <div class="col-md">
        <div class="row justify-content-end">
          <div class="col text-end">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="검색" aria-label="search-value"
                v-model="searchValue" />
              <button type="button" class="btn btn-primary" @click="getDatas">검색</button>
              <button type="button" class="btn btn-outline-secondary" @click="resetDatas"><font-awesome-icon
                  icon="fa-solid fa-eraser" /></button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <LoadingSpinner v-if="isLoading"></LoadingSpinner>
      <div class="row gx-2 justify-content-end">
        <!-- S: 페이지 네비게이션 -->
        <div class="col-md-10 d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-secondary me-2" :disabled="currentPage === 1"
            @click="navigatePage(currentPage - 1)">
            <font-awesome-icon icon="fa-solid fa-chevron-left" />
          </button>
          <span>
            페이지 {{ currentPage }} / {{ totalPages }} (총 {{ totalCount }} 건)
          </span>
          <button type="button" class="btn btn-secondary ms-2" :disabled="currentPage === totalPages"
            @click="navigatePage(currentPage + 1)">
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </button>
        </div>
        <!-- E: 페이지 네비게이션 -->
        <div class="col-md-2 text-end">
          <button class="btn btn-outline-secondary btn-sm" style="width: auto;" @click="onBtnExport">Excel 내보내기</button>
        </div>
      </div>

      <GridTemplate ref="grid" :columns="columnDefs" :dataSource="dataSource" @grid-ready="onGridReady"
        :framework-components="frameworkComponents" :cellSelection="cellSelection"
        :enableCellTextSelection="enableCellTextSelection" :autoSizeStrategy="autoSizeStrategy" />
    </div>

    <!-- S: 수정/추가 레이어 모달 -->
    <div v-if="showEditModal" class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isAdding ? '이지쌤 추가' : '이지쌤 수정' }}</h5>
            <button type="button" class="close" @click="closeEditModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="teacherName">이름</label>
              <input type="text" id="teacherName" class="form-control" v-model="selectedTeacher.teacherName"
                placeholder="이지쌤의 이름을 입력해주세요" />
            </div>
            <div class="form-group">
              <label for="teacherPhone">연락처</label>
              <input type="text" id="teacherPhone" class="form-control" v-model="selectedTeacher.teacherPhone"
                placeholder="이지쌤의 연락처를 입력해주세요(010-1234-5678)" />
            </div>
            <div class="form-group">
              <label for="teacherBirth">생년월일</label>
              <input type="text" id="teacherBirth" class="form-control" v-model="selectedTeacher.teacherBirth"
                placeholder="이지쌤의 생년월일을 입력해주세요(2000-01-01)" />
            </div>
            <div class="form-group">
              <label for="teacherRegion">지역</label>
              <input type="text" id="teacherRegion" class="form-control" v-model="selectedTeacher.teacherRegion"
                placeholder="이지쌤의 지역을 입력해주세요(강원원주)" />
            </div>
            <div class="form-group">
              <label for="teacherGender">성별</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="genderMale" value="M"
                    v-model="selectedTeacher.teacherGender" />
                  <label class="form-check-label" for="genderMale">남성</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="genderFemale" value="F"
                    v-model="selectedTeacher.teacherGender" />
                  <label class="form-check-label" for="genderFemale">여성</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="teacherLink">고유링크</label>
              <div class="input-group">
                <input type="text" id="teacherLink" class="form-control" v-model="selectedTeacher.teacherLink"
                  placeholder="이지쌤의 고유링크를 입력해주세요(자동부여)" />
                <button type="button" class="btn btn-outline-secondary" @click="generateUniqueLink">자동부여</button>
              </div>
            </div>
            <div class="form-group">
              <label for="bankName">은행명</label>
              <input type="text" id="bankName" class="form-control" v-model="selectedTeacher.bankName"
                placeholder="이지쌤의 은행명을 입력해주세요(한국은행)" />
            </div>
            <div class="form-group">
              <label for="bankAccount">계좌번호</label>
              <input type="text" id="bankAccount" class="form-control" v-model="selectedTeacher.bankAccount"
                placeholder="이지쌤의 은행계좌번호를 입력해주세요(01-234-56789)" />
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <!-- 삭제 버튼: 항상 보이지만, isAdding일 때 비활성화 -->
            <button type="button" class="btn btn-danger" :disabled="isAdding" @click="deleteTeacher">삭제</button>
            <div>
              <button type="button" class="btn btn-primary" @click="isAdding ? addTeacher() : saveTeacher()">저장</button>
              <button type="button" class="btn btn-secondary" @click="closeEditModal">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- E: 수정/추가 레이어 모달 -->

    <!-- S: 엑셀 파일 포맷 레이어 모달 -->
    <div v-if="showExcelModal" class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">엑셀 파일 업로드 형식 안내</h5>
            <button type="button" class="close" @click="showExcelModal = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>업로드할 엑셀 파일 포맷:</p>
            <ol>
              <li>이름 (문자열)</li>
              <li>연락처 (형식: 010-1234-5678)</li>
              <li>생년월일 (형식: yyyy-MM-dd)</li>
              <li>지역 (예: 경기광주/강원원주/...)</li>
              <li>성별 (M/F)</li>
              <li>고유링크 (문자열)</li>
              <li>계좌은행명 (문자열)</li>
              <li>계좌번호 (형식: 123-456-7890)</li>
            </ol>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showExcelModal = false">닫기</button>
            <button type="button" class="btn btn-primary" @click="downloadSampleExcel">샘플 다운로드</button>
          </div>
        </div>
      </div>
    </div>
    <!-- E: 엑셀 파일 포맷 레이어 모달 -->

  </div>
</template>

<script>
import axios from 'axios';
import TEACHER from "@/api/teacher.js";
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ButtonRenderer from '@/components/ButtonRenderer.vue';

export default {
  name: "TeacherView",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      gridApi: null,
      showExcelModal: false,
      columnDefs: [
        { headerName: "이름", field: "teacherName", pinned: 'left' },
        { headerName: "연락처", field: "teacherPhone" },
        { headerName: "생년월일", field: "teacherBirth" },
        { headerName: "지역", field: "teacherRegion" },
        {
          headerName: "성별",
          field: "teacherGender",
          valueFormatter: (params) => {
            // M은 남성, F는 여성으로 변환
            if (params.value === 'M') return '남성';
            if (params.value === 'F') return '여성';
            return params.value; // 값이 없거나 예상치 못한 값인 경우 그대로 표시
          },
        },
        { headerName: "고유링크", field: "teacherLink" },
        { headerName: "은행명", field: "bankName" },
        { headerName: "계좌번호", field: "bankAccount" },
        { headerName: "생성일시", field: "createdDt" },
        {
          headerName: "제어", field: "actions", colId: "actions", pinned: 'right',
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params) => ({
            label: '수정',
            onClick: () => {
              if (params && params.data) {
                this.openEditModal(params.data); // this가 Vue 인스턴스를 참조
              }
            },
          }),
        },
      ],
      enableCellTextSelection: true,
      cellSelection: true,
      dataSource: [],
      defaultColDef: {
        sortable: true,
        flex: 1,
        resizable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
      },
      autoSizeStrategy: {
        type: 'fitGridWidth',
        defaultMinWidth: 30,
      },
      frameworkComponents: {
        ButtonRenderer: ButtonRenderer, // ButtonRenderer 컴포넌트 등록
      },
      searchValue: '',
      totalCount: 0,
      currentPage: 1,
      rowsPerPage: 100,
      isLoading: false,
      isAdding: false, // 추가 모드 플래그
      showEditModal: false,
      selectedTeacher: {},
    }
  },
  created() {
    let date = new Date();
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000));
    this.endDt = date.toISOString().split('T')[0]
    this.getDatas();
  },
  computed: {
    totalPages() {
      // 전체 페이지 수 계산
      return Math.ceil(this.totalCount / this.rowsPerPage);
    },
  },
  methods: {
    showExcel() {
      this.showExcelModal = true;
    },
    downloadSampleExcel() {
      const link = document.createElement('a');
      link.href = '/assets/sang/sample_teacher_upload.xlsx';
      link.download = 'sample_teacher_upload.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onGridReady(params) {
      console.log('Grid Ready Params:', params); // API 확인
      this.gridApi = params.api;
      if (!this.gridApi) {
        console.error('Grid API 초기화 실패');
      } else {
        console.log('Grid API가 정상적으로 초기화되었습니다.');
      }
    },
    onBtnExport() {
      console.log('Grid onBtnExport');
      if (!this.gridApi) {
        console.error('Grid API가 아직 초기화되지 않았습니다.');
        alert('데이터를 내보낼 수 없습니다. 그리드가 아직 준비되지 않았습니다.');
        return;
      }
      this.isLoading = true;
      try {
        let { searchValue, currentPage, rowsPerPage } = this;
        const param = {
          search_value: searchValue,
          current_page: currentPage,
          rows_per_page: rowsPerPage,
        };
        TEACHER.getDownload(param);
        console.log('CSV 내보내기 완료');
      } catch (error) {
        console.error('CSV 내보내기 중 오류 발생:', error);
        alert('CSV 내보내기 중 오류가 발생했습니다. 콘솔을 확인하세요.');
      }
      this.isLoading = false;
    },
    async getDatas() {
      try {
        let { searchValue, currentPage, rowsPerPage } = this;
        const param = {
          search_value: searchValue,
          current_page: currentPage,
          rows_per_page: rowsPerPage,
        };
        this.isLoading = true;
        const { data } = await TEACHER.getList(param);
        console.log('이지쌤 목록:', data);
        if (data !== 'undefined' && data != null) {
          this.totalCount = data.count;
          const results = data.results;
          console.debug('이지쌤 수:', this.totalCount);
          this.dataSource = results;
        }
      } catch (error) {
        alert(error.data);
        console.log("오류: ", error);
      }
      this.isLoading = false;
    },
    navigatePage(page) {
      if (page < 1 || page > this.totalPages) return; // 페이지 범위를 벗어난 경우 무시
      this.currentPage = page; // 현재 페이지 변경
      this.getDatas(); // 데이터 다시 로드
    },
    resetDatas() {
      this.searchValue = '';
    },
    async uploadFile() {
      const file = this.$refs.fileInput.files[0];
      if (!file) {
        alert('파일을 선택해 주세요.');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);

      try {
        // 인증 토큰 가져오기
        const token = localStorage.getItem('token'); // 토큰이 저장된 위치를 지정 (예시: localStorage)

        const response = await axios.post('/api/teacher/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}` // 인증 토큰 추가
          }
        });
        // const response = await TEACHER.uploadFile(formData);
        alert('파일 업로드 성공: ' + response.data.message);
      } catch (error) {
        console.error('파일 업로드 실패:', error);
        alert('파일 업로드 중 문제가 발생했습니다.');
      }
    },
    openEditModal(teacher) {
      this.isAdding = false;
      this.selectedTeacher = { ...teacher };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async generateUniqueLink() {
      try {
        const response = await TEACHER.getNumber();
        console.log('고유링크 자동 부여 결과:', response.data);
        this.selectedTeacher.teacherLink = response.data;
        alert('고유링크가 자동으로 부여되었습니다: ' + response.data);
      } catch (error) {
        console.error('고유링크 자동 부여 실패:', error);
        alert('고유링크 자동 부여 중 문제가 발생했습니다.');
      }
    },
    saveTeacher() {
      // Grid API가 초기화되지 않은 경우 예외 처리
      if (!this.gridApi) {
        console.error('Grid API가 아직 준비되지 않았습니다.');
        alert('Grid API가 아직 준비되지 않았습니다. 다시 시도해 주세요.');
        return;
      }
      // 저장 로직 구현
      TEACHER.update(this.selectedTeacher);

      // `this.dataSource`에서 수정된 행을 찾고 업데이트
      const index = this.dataSource.findIndex(
        (row) => row.id === this.selectedTeacher.id
      );
      if (index !== -1) {
        // 데이터 수정 (Vue 3에서 반응성을 유지하려면 배열 재할당)
        this.dataSource = [
          ...this.dataSource.slice(0, index),
          { ...this.selectedTeacher },
          ...this.dataSource.slice(index + 1),
        ];

        // 그리드 데이터를 다시 설정
        this.gridApi.setRowData(this.dataSource);

        alert('이지쌤 정보가 저장되었습니다.');
        console.log('이지쌤 정보가 저장되었습니다.', this.selectedTeacher);
      } else {
        console.warn('수정하려는 이지쌤 데이터가 dataSource에 존재하지 않습니다.');
      }
      this.closeEditModal();
    },
    openAddModal() {
      this.selectedTeacher = {
        teacherName: '',
        teacherPhone: '',
        teacherGender: 'M', // 기본값: 남성
        teacherLink: '',
        bankAccount: '',
      };
      this.isAdding = true;
      this.showEditModal = true;
    },
    addTeacher() {
      // 저장 로직 구현
      TEACHER.update(this.selectedTeacher)
        .then(() => {
          alert('새로운 이지쌤이 추가되었습니다.');
          this.getDatas();
        })
        .catch((error) => {
          console.error('새로운 이지쌤이 추가 실패:', error);
          alert('새로운 이지쌤이 추가중 문제가 발생했습니다.');
        });
      this.closeEditModal();
    },
    deleteTeacher() {
      if (!this.selectedTeacher.id) {
        alert('삭제할 항목이 선택되지 않았습니다.');
        return;
      }

      // 확인 메시지
      if (!confirm('정말로 이 이지쌤을 삭제하시겠습니까?')) {
        return;
      }

      // 서버에 삭제 요청 (비동기)
      TEACHER.deleteData(this.selectedTeacher)
        .then(() => {
          alert('이지쌤 정보가 삭제되었습니다.');
          // 삭제 로직: dataSource에서 해당 항목 제거
          this.dataSource = this.dataSource.filter(
            (teacher) => teacher.id !== this.selectedTeacher.id
          );

          // Grid 업데이트
          if (this.gridApi) {
            this.gridApi.setRowData(this.dataSource);
          }
        })
        .catch((error) => {
          console.error('삭제 실패:', error);
          alert('삭제 중 문제가 발생했습니다.');
        });

      // 모달 닫기
      this.closeEditModal();
    },
  }
}
</script>

<style scoped>
.btn {
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  line-height: 1;
}

.form-check {
  margin-right: 10px;
  /* 라디오 버튼 간의 간격 */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  max-width: 500px;
  width: 100%;
}

.modal-header {
  display: flex;
  align-items: center;
}

.modal-header .close {
  margin-left: auto;
  /* 닫기 버튼을 오른쪽 끝으로 이동 */
  background: none;
  /* 불필요한 배경 제거 */
  border: none;
  /* 불필요한 테두리 제거 */
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer .btn-danger {
  margin-right: auto;
  /* 왼쪽 정렬 */
}

.modal-footer div {
  display: flex;
  gap: 10px;
  /* 저장 및 닫기 버튼 간 간격 */
}
</style>
