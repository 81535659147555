<template>
  <section class="vh-100 overflow-auto" style="height: 100%">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>

    <!-- 상단 배너 -->
    <div class="header d-flex justify-content-between align-items-center py-3">
      <button class="btn btn-link back-btn" @click="goBackward">
        <font-awesome-icon icon="fa fa-arrow-left" />
      </button>
      <div class="logo-box">
        <img :src="ezlogo" class="header-logo" alt="농업e지 로고" />
      </div>
    </div>
    <!-- 인포 라벨 -->
    <div class="step-label py-3 text-left">
      <h1>Step 2. 농업e지 이용안내</h1>
    </div>
    <!-- 하위 라벨 -->
    <div class="stepunder-label py-3 text-left">
      <h2>Step 2-2. 농식품사업신청</h2>
    </div>
    <!-- 첫 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">01</p>
          <p class="fs-2">
            <strong>농식품사업 탐색</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            [사업신청] - [전체사업] 메뉴에서 농식품사업 목록을 볼 수 있어요.
            <br />원하는 사업이 있다면 검색해보거나,
            <br />[인기사업] 혹은 [맞춤사업]을 이용해 보세요.
            <br />로그인하면 나에게 딱 맞는 맞춤사업도 알려드려요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_5" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 두 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">02</p>
          <p class="fs-2">
            <strong>맞춤사업 확인</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            [농업e지 원패스] - [맞춤사업]에서
            <br />내가 신청 가능한 맞춤사업을 볼 수 있어요.
            <br />[맞춤사업 전체보기] 버튼을 누르면
            <br />전체목록으로 이동해요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_6" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 세 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">03</p>
          <p class="fs-2">
            <strong>사업 신청</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            신청하고 싶은 사업을 찾았다면 [즉시지원]
            <br />버튼을 눌러 내 핸드폰으로 편하게 지원해 보세요.
            <br />더 이상 사업 신청을 위해 번거롭게
            <br />읍·면·동 사무소를 방문하지 않아도 돼요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_7" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 네 번째 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted">04</p>
          <p class="fs-2">
            <strong>사업신청내역 확인</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            사업 신청을 완료했다면,
            <br />[마이페이지] - [내 사업] 사업신청내역 탭에서
            <br />신청내역과 현황을 볼 수 있어요.
            <br />농업e지로 쉽고 편하게 농식품사업을 신청하세요.
          </p>
          <div class="image-container text-center">
            <img :src="ezstep2_8" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <!-- 다음으로 -->
    <div class="text-left mx-auto d-grid mb-4">
      <div class="text-left mt-4">
        <button class="btn btn-primary btn-lg full-width-btn" @click="goForward">
          <span class="thin-text">다음으로</span><br>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "LinkView4",
  components: {
    LoadingSpinner, 
  },
  data() {
    return {
      ezlogo: "/assets/images/ez_logo.png",
      ezstep2_5: "/assets/images/ez_step2_5.png",
      ezstep2_6: "/assets/images/ez_step2_6.png",
      ezstep2_7: "/assets/images/ez_step2_7.png",
      ezstep2_8: "/assets/images/ez_step2_8.png",
    };
  },
  mounted() { },
  methods: {
    goBackward() {
      this.isLoading = true;
      this.$router.push("/link3");
    },
    goForward() {
      this.isLoading = true;
      this.$router.push("/link5");
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.back-btn {
  font-size: 1.5rem;
  color: #505050;
  border: none;
  cursor: pointer;
}

.header-logo {
  width: 100px;
  height: auto;
}

.step-label {
  background-color: #f95a25;
  color: white;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.stepunder-label {
  background-color: #eaeaea;
  color: black;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  font-size: 1rem !important;
}

.step-label h1 {
  font-size: 1.2rem;
  margin: 0;
}

.stepunder-label h2 {
  font-size: 1.1rem;
}

.text-center p {
  color: #6c757d;
}

.text-left {
  width: 100%;
  text-align: left;
  margin: 0;  
}

.text-left p {
  text-align: left !important;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  color: #000000;
  font-size: 1rem;
}

.fs-1 {
  font-size: 0.9rem !important;
  color: #f95a25 !important;
}

.fs-2 {
  font-size: 1rem;
  margin-top: 20px;
}

strong {
  color: #000000;
  font-weight: bold;
}

.center-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 40px auto;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
  max-width: 640px;
  min-height: 420px;
  box-sizing: border-box;
}

.image-container {
  text-align: center;
  margin-top: 20px;
  max-width: 450px;
  width: auto;
  height: auto;
}

.content {
  width: 100%;
}

.full-width-btn {
    background-color: #2c387e;
    border: none;
    font-size: 1rem;
    padding: 15px 30px;
    text-align: center;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto; 
    position: relative;
}
</style>