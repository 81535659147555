const SCREEN_COLUMN = [
    { headerName: "meaDat(작동일시)", field: "meaDat", pinned: 'left' },
    // { headerName: "id", field: "id" },
    { headerName: "usrId(온실명)", field: "usrId" },
    { headerName: "farmCde(온실)", field: "farmCde" },
    { headerName: "mkey(기기넘버링)", field: "mkey" },
    { headerName: "val(작동여부)", field: "val", type: 'numericColumn' },
    { headerName: "opp(모드)", field: "opp" },
    { headerName: "nam(네임)", field: "nam" },
    { headerName: "status(작동여부)", field: "status" },
    { headerName: "createdDt", field: "createdDt" },
    //Mkey: 1-천창(좌), 2 – 천창(우), 3-측창(좌), 4 – 측창(우), 5-보온(상부), 6-보온(측면),  7- 차광
];

const ECO_COLUMN = [
    { headerName: "meaDat(기록일시)", field: "meaDat", pinned: 'left' },
    // { headerName: "id", field: "id" },
    { headerName: "rain(비)", field: "rain", type: 'numericColumn' },
    { headerName: "windspeed(풍속)", field: "windspeed", type: 'numericColumn' },
    { headerName: "rad(외부일사량)", field: "rad", type: 'numericColumn' },
    { headerName: "winddir", field: "winddir", type: 'numericColumn' },
    { headerName: "temp(외부온도)", field: "temp", type: 'numericColumn' },
    { headerName: "hum(외부습도)", field: "hum", type: 'numericColumn' },
    { headerName: "createdDt", field: "createdDt" },
]

const SENSOR_COLUMN = [
    { headerName: "meaDat(기록일시)", field: "meaDat", pinned: 'left' },
    // { headerName: "id", field: "id" },
    { headerName: "farmCde(온실)", field: "farmCde" },
    { headerName: "temp(실내온도)", field: "temp", type: 'numericColumn' },
    { headerName: "hum(실내습도)", field: "hum", type: 'numericColumn' },
    { headerName: "co2(잔존co2)", field: "co2", type: 'numericColumn' },
    { headerName: "rad(일사량)", field: "rad", type: 'numericColumn' },
    { headerName: "radS(누적일사량)", field: "radS", type: 'numericColumn' },
    { headerName: "soilT(토양온도)", field: "soilT", type: 'numericColumn' },
    { headerName: "soilH(토양습도)", field: "soilH", type: 'numericColumn' },
    { headerName: "phS(급액ph)", field: "phS", type: 'numericColumn' },
    { headerName: "ecS(급액ec)", field: "ecS", type: 'numericColumn' },
    { headerName: "psE(배액ph)", field: "psE", type: 'numericColumn' },
    { headerName: "ecE(배액ec)", field: "ecE", type: 'numericColumn' },
    { headerName: "createdDt", field: "createdDt" },
]

const ONOFF_COLUMN = [
    { headerName: "meaDat(작동일시)", field: "meaDat", pinned: 'left' },
    // { headerName: "id", field: "id" },
    { headerName: "usrId", field: "usrId" },
    { headerName: "farmCde(온실)", field: "farmCde" },
    { headerName: "mkey(기기넘버링)", field: "mkey" },
    { headerName: "val(작동여부)", field: "val", type: 'numericColumn' },
    { headerName: "opp(모드)", field: "opp" },
    { headerName: "nam(네임)", field: "nam" },
    { headerName: "status(작동여부)", field: "status" },
    { headerName: "createdDt", field: "createdDt" },
    // Mkey: 1-배기펜, 2 – 유동펜, 3-CO2, 4 – 인공광, 5-난방기, 6 – 관수(양액)
]

const KMA_WEATHER_COLUMN = [
    { headerName: "meaDat(예보일시)", field: "meaDat", pinned: 'left' },
    // { headerName: "id", field: "id" },
    { headerName: "pcp(1시간 강수량)", field: "pcp" },
    { headerName: "sky(하늘상태)", field: "sky", type: 'numericColumn' },
    { headerName: "sno(1시간 적설)", field: "sno" },
    { headerName: "pop(강수확률)", field: "pop", type: 'numericColumn' },
    { headerName: "pty(강수형태)", field: "pty", type: 'numericColumn' },
    { headerName: "reh(습도)", field: "reh", type: 'numericColumn' },
    { headerName: "tmp(1시간 기온)", field: "tmp", type: 'numericColumn' },
    { headerName: "tmn(일 최저기온)", field: "tmn", type: 'numericColumn' },
    { headerName: "tmx(일 최고기온)", field: "tmx", type: 'numericColumn' },
    { headerName: "uuu(풍속:동서성분)", field: "uuu", type: 'numericColumn' },
    { headerName: "vvv(풍속:남북성분)", field: "vvv", type: 'numericColumn' },
    { headerName: "wav(파고)", field: "wav", type: 'numericColumn' },
    { headerName: "vec(풍향)", field: "vec", type: 'numericColumn' },
    { headerName: "wsd(풍속)", field: "wsd", type: 'numericColumn' },
    { headerName: "예보 등록", field: "baseDt" },
    { headerName: "데이터 등록", field: "createdDt" },
    // Mkey: 1-배기펜, 2 – 유동펜, 3-CO2, 4 – 인공광, 5-난방기, 6 – 관수(양액)
]

export { SCREEN_COLUMN, ECO_COLUMN, SENSOR_COLUMN, ONOFF_COLUMN, KMA_WEATHER_COLUMN };