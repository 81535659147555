import axios from "axios";
import { setInterceptors } from "./interceptors";

function create() {
	const instance = axios.create({
		baseurl: process.env.VUE_APP_API_SERVER_URL,
	});
	return setInterceptors(instance);
}

export const axiosService = create();
