<template>
  <section class="vh-100 overflow-auto" style="height: 100%">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>
    
    <div class="container-fluid" style="padding-top: 5%;">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="form-outline mb-4 text-center">
          <!-- 농업e지 로고 -->
          <div class="image-container">
            <img :src="ezlogo" class="centered-image" style="width: 200px; height: auto; margin-bottom: 20px;" />
          </div>
          <!-- 하위 라벨 -->
          <label class="col-12 col-form-label-sm text-center fs-5 text-muted" for="form3Example3" style="font-weight: 350;">
            차세대 농업농촌통합정보시스템
            <div v-html="description"></div>&nbsp;
          </label>
          <!-- 인포 박스 -->
          <div class="info-box text-white">
            <h1 class="mb-4 text-left">&nbsp;농업경영체 등록돼있는 누구나</h1>
            <h2 class="fw-bold mb-5 text-left">&nbsp;농업e지 교육<br>&nbsp;온라인 과정</h2>
            <p class="mb-4 text-left">
              &nbsp;나한테 딱 맞는 농업보조금?<br>
              &nbsp;농업경영체 정보 확인?<br>
              &nbsp;농업e지에 물어봐!!
            </p>
            <div class="illustration">
              <img :src="illustration" alt="농업 일러스트" />
            </div>
          </div>
        </div>
        <!-- 시작 버튼 -->
        <div class="text-left mx-auto d-grid">
          <div class="text-left mt-4">
            <button class="btn btn-primary btn-lg full-width-btn" @click="goNext">
              <span class="thin-text">나에게 필요한</span><br>
              <strong>농업e지&nbsp;</strong>
              <span class="thin-text">교육 시작하기</span>
              <span class="arrow-icon">></span>
            </button>
          </div>
        </div>
        <!-- 이지쌤 식별 카드 -->
        <div class="bottom-box text-center">
          <div class="d-flex justify-content-center align-items-center">
            <img :src="ezcharacter" class="bottom-img" alt="농업e지쌤" />
            <div class="bottom-text">
              <h3>농업e지쌤</h3>
              <hr class="bottom-line" />
              <p>{{ teacherName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FRONT from "@/api/front.js";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "LinkView",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      ezlogo: "/assets/images/ez_logo.png",
      illustration: "/assets/images/home_banner.png",
      ezcharacter: "/assets/images/ez_character.png",
      teacherName: '홍길동(상주)',
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let teacherLink = urlParams.get('a');
    if (!teacherLink) {
      console.error('LinkView.이지쌤 링크 없음:', teacherLink);
      if (localStorage.getItem("teacherLink")) {
        teacherLink = localStorage.getItem("teacherLink");
        console.debug('LinkView.localStorage.teacherLink:', teacherLink);
      }
    }
    if (teacherLink) {
      console.debug('LinkView.teacherLink:', teacherLink);
      localStorage.setItem("teacherLink", teacherLink);

      // 주소창 업데이트
      const newUrl = `${window.location.origin}/link?a=${teacherLink}`;
      window.history.replaceState(null, '', newUrl);
      
      const param = {
        teacher_link: teacherLink,
      };
      console.debug('LinkView.param:', param);
      FRONT.check(param)
        .then(response => { 
          this.teacherName = response.data.replace(/\d+$/, '').trim();
          this.teacherId = response.data.match(/\d+$/)[0];
          localStorage.setItem("teacherName", this.teacherName);
          localStorage.setItem("teacherId", this.teacherId);
        })
        .catch(error => {
          console.error('LinkView.이지쌤 이름 읽어오기 실패:', error);
        });
    }
  },
  methods: {
    goNext() {
      console.debug("1234");
      this.isLoading = true;
      this.$router.push("/link2");
    },
  }
};
</script>

<style scoped>
.container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}

.logo-box {
  text-align: center;
  margin-bottom: 20px;
}

.logo-box img {
  display: block;
  margin: 0 auto;
}

.info-box {
  background-color: #f95a25;
  border-radius: 16px;
  padding: 20px;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin-bottom: 0px !important;
  max-width: 600px;
  margin: 0 auto;
}

.info-box h1,
.info-box h2,
.info-box p {
  text-align: left;
  margin-bottom: 15px;
}

.info-box h1 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.info-box h2 {
  font-size: 2rem;
  line-height: 1.4;
}

.info-box p {
  font-size: 1rem;
  line-height: 1.5;
}

.illustration {
  position: absolute;
  bottom: 0;
  right: 10px;
}

.illustration img {
  width: 120px;
  height: auto;
  object-fit: contain;
}

.text-left {
  text-align: center;
  width: 100%;
}

.full-width-btn {
  background-color: #2c387e;
  border: none;
  font-size: 1.2rem;
  padding: 15px 30px;
  text-align: center;
  width: 100%;
  max-width: 600px;
  border-radius: 16px;
  margin: 0 auto;
  position: relative;
}

.arrow-icon {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.full-width-btn .thin-text {
  font-weight: 300;
  font-size: 1.2rem;
}

.bottom-box {
  background-color: #f0f0f0;
  padding: 15px 30px;
  border-radius: 16px;
  margin-top: 40px;
  width: 94%;
  margin-bottom: 20px;
  max-width: 600px;
  box-sizing: border-box;
}

.bottom-img {
  width: 100px;
  height: 110px;
  margin-right: 15px;
}

.bottom-text h3 {
  margin: 0;
  font-size: 1.7rem;
}

.bottom-text p {
  margin: 0;
  font-size: 1.2rem;
  color: #6c757d;
}

.bottom-line {
  border: 1px solid #6c757d;
  margin: 10px 0;
  width: 100%;
}
</style>
