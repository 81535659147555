<template>
  <div id="app" style="overflow: auto;">
    <div v-if="currentView === 'link'">
      <router-view></router-view>
    </div>
    <div v-else-if="currentView === 'login'">
      <router-view></router-view>
    </div>
    <div v-else>
      <AppMainContent></AppMainContent>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import AppMainContent from "@/components/AppMainContent.vue";

export default {
  name: 'App',
  components: {
    AppMainContent,
  },
  data() {
    return {
      currentView: 'link', // 초기 화면은 LinkView
    };
  },
  methods: {
    ...mapMutations("user", ["INIT_USER_AUTH"]),
    async init() {
      const loggedIn = JSON.parse(this.$getLocalStorageItem("loggedIn") || "false");
      if (loggedIn) {
        const token = this.$getLocalStorageItem("token");
        this.INIT_USER_AUTH({ loggedIn, token });
        this.currentView = 'main'; // 로그인 상태일 경우 메인 화면으로 이동
      }
    },
    showLoginView() {
      this.currentView = 'link'; // LinkView에서 로그인 버튼 클릭 시 LoginView로 전환
    },
    handleLoginSuccess() {
      this.currentView = 'main'; // LoginView에서 로그인 성공 시 메인 화면으로 전환
    },
  },
  created() {
    this.init();
  },
};
</script>

<style></style>
