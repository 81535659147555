import moment from "moment";

export default {
    install: app => {
        app.config.globalProperties.$isEmpty = data => {
            if (
                data == "" ||
                data == null ||
                data == undefined ||
                data.length < 1
            ) {
                return true;
            }
            return false;
        };

        app.config.globalProperties.$isNull = data => {
            return data === null || data === undefined;
        };

        app.config.globalProperties.$isSame = (item1, item2) => {
            if (item1 == item2) {
                return true;
            }
            return false;
        };

        app.config.globalProperties.$sortItems = (items, key) => {
            return items.sort((a, b) => {
                return Number(a[key]) < Number(b[key])
                    ? -1
                    : Number(a[key]) > Number(b[key])
                        ? 1
                        : 0;
            });
        };

        app.config.globalProperties.$copyDeep = data => {
            return JSON.parse(JSON.stringify(data));
        };

        app.config.globalProperties.$moment = moment;

        app.config.globalProperties.$setLocalStorageItem = (key, value) => {
            localStorage.setItem(key, value);
        };

        app.config.globalProperties.$getLocalStorageItem = key => {
            return localStorage.getItem(key) ?? "";
        }
    },
};