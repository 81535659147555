<template>
  <section class="vh-100 overflow-auto" style="height: 100%">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>

    <!-- 상단 배너 -->
    <div class="header d-flex justify-content-between align-items-center py-3">
      <button class="btn btn-link back-btn" @click="goBackward">
        <font-awesome-icon icon="fa fa-arrow-left" />
      </button>
      <div class="logo-box">
        <img :src="ezlogo" class="header-logo" alt="농업e지 로고" />
      </div>
    </div>
    <!-- 인포 라벨 -->
    <div class="step-label py-3 text-left">
      <h1>이벤트 참여하기</h1>
    </div>
    <!-- 설명 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <div v-html="description"></div>&nbsp;
          <p class="fs-1 text-muted">혜택</p>
          <p class="fw-light" style="font-size: 1rem;">
            2024년 12월까지 신청하신 모든분들께 추첨을 통해
            <strong>5,000원 모바일 상품권</strong>을 보내드립니다
          </p>
          <div v-html="description"></div>&nbsp;
        </div>
        <div class="text-left">
          <p class="fs-1 text-muted">방법</p>
          <p class="fw-light" style="font-size: 1rem;">
            1. 개인정보동의 및 마케팅이용 동의를 체크한다
            <br />2. 이벤트 참여하기 버튼을 누른다
          </p>
        </div>
      </div>
    </div>
    <!-- 동의 박스 -->
    <div class="container-fluid">
      <div class="center-box">
        <div class="text-left">
          <p class="fs-1 text-muted"><strong>이벤트에 참가하기 위해서</strong>
            <br /><strong>아래 이용약관에 동의해주세요.</strong>
          </p>
          <p class="fw-light" style="font-size: 1rem;">
            모두 동의해야 이벤트에 참가할 수 있어요.
          </p>
        </div>
      </div>
    </div>
    <!-- 동의 체크란 -->
    <div class="container mt-4">
      <div class="center-box">
        <form>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <div style="font-size: larger; font-weight: bold; text-align: left;">
              <input type="checkbox" id="agreeAll" v-model="agreeAll" class="form-check-input" />
              <label for="agreeAll" class="form-check-label">
                &nbsp;모두 동의합니다.
              </label>
            </div>
            <div class="icon-wrapper" style="text-align: right; cursor: pointer;" @click="showPopup">
              <font-awesome-icon icon="fa fa-angle-right" class="popup-trigger-icon" />
            </div>
          </div>
          <div class="mb-3">
            <input type="checkbox" id="agreePersonal" v-model="agreePersonal" class="form-check-input" />
            <label for="agreePersonal" class="form-check-label">&nbsp;개인정보 수집 및 이용 동의(필수)</label>
          </div>
          <div class="mb-3">
            <input type="checkbox" id="agreeMarketing" v-model="agreeMarketing" class="form-check-input" />
            <label for="agreeMarketing" class="form-check-label">&nbsp;마케팅 정보 수신 동의(필수)</label>
          </div>
        </form>
      </div>
    </div>

    <!-- 다음으로 -->
    <div class="text-left mx-auto d-grid">
      <div class="text-left mt-4">
        <button class="btn btn-primary btn-lg full-width-btn" @click="goForward" :disabled="!agreeAll">
          <span class="thin-text">동의하고 이벤트 참여하기</span><br>
        </button>
      </div>
    </div>
    <div class="text-left mx-auto d-grid mb-4">
      <div class="text-left mt-4">
        <button class="btn btn-secondary btn-lg full-width-btn" @click="goForward">
          <span class="thin-text">참여안하고 수료하기</span><br>
        </button>
      </div>
    </div>
    <!-- S: 팝업 레이어: 이벤트 참여 개인정보 수집정책 -->
    <div :class="['slide-popup', { visible: isPopupVisible }]">
      <div class="popup-header">
        <button class="close-button" @click="closePopup">
          <font-awesome-icon icon="fa fa-arrow-left" />
          <span class="ms-2">닫기</span>
        </button>
      </div>
      <p class="scrollable-content fw-light" style="font-size: 0.5rem;">
        <span style="font-size: large; font-weight: bold;">a.개인정보 수집 및 이용 동의</span>
        <br />(주) 팜러닝 (이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
        개인정보 처리지침을 수립·공개합니다.
        <br />
        <br />제1조(개인정보의 처리목적)
        <br />회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에
        따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        <br />1. 홈페이지 회원 가입 및 관리
        <br />회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보
        처리시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
        <br />2. 재화 또는 서비스 제공
        <br />물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
        <br />3. 고충처리
        <br />민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.
        <br />
        <br />제2조(개인정보의 처리 및 보유기간)
        <br />① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
        <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
        <br />1. 홈페이지 회원 가입 및 관리 : 홈페이지 탈퇴 후 5일까지
        <br />다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
        <br />1) 관계 법령 위반에 따른 수사·조사 등이 진행중인 경우에는 해당 수사·조사 종료시까지
        <br />2) 홈페이지 이용에 따른 채권·채무관계 잔존시에는 해당 채권·채무관계 정산시까지
        <br />2. 재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산 완료시까지
        <br />다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
        <br />1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
        <br />- 표시·광고에 관한 기록 : 6월
        <br />- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
        <br />- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
        <br />2)「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
        <br />- 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년
        <br />- 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
        <br />
        <br />제3조(개인정보처리의 위탁)
        <br />① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
        <br />1. 시스템 운영
        <br />- 위탁받는 자 (수탁자) : (주)맑은소프트
        <br />- 위탁하는 업무의 내용 : 교육관리시스템(LMS) 관리 및 유지보수
        <br />② 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등
        책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
        <br />
        <br />제4조(정보주체의 권리·의무 및 행사방법)
        <br />① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
        <br />1. 개인정보 열람요구
        <br />2. 오류 등이 있을 경우 정정 요구
        <br />3. 삭제요구
        <br />4. 처리정지 요구
        <br />② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
        <br />③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
        <br />④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을
        제출하셔야 합니다.
        <br />⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
        <br />⑥ 만 14세 미만 아동의 법정대리인의 법령 상의 권리를 보장합니다. (아동의 개인정보에 대한 열람, 정정, 삭제, 개인정보처리정지요구권)
        <br />
        <br />제5조(처리하는 개인정보 항목)
        <br />①회사는 다음의 개인정보 항목을 처리하고 있습니다.
        <br /> 1. 홈페이지 회원 가입 및 관리
        <br /> - 항목 : 성명, 아이디, 비밀번호, 전자우편주소(이메일), 생년월일, 성별, 주소, 핸드폰(연락처)
        <br /> 2. 재화 또는 서비스 제공
        <br /> - 필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 전자우편주소(이메일)
        <br /> 3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
        <br /> - IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
        <br />②수집 및 이용 목적
        <br /> 1. 농업e지 쌤 등록 및 사업 운영 사무/ 농업e지 쌤 명단 관리 / 농업인 대상 교육 진행 시 명단 제공
        <br />③보유 및 이용기간
        <br /> 1. 5년
        <br />④개인정보 제 3자 제공 동의
        <br /> 1. 제공받는 자
        <br /> - 농림축산식품부장관, 소속 도지사 및 시군구청장, 농림수산식품교육문화정보원장, 운영 위탁기관(업체)
        <br /> 2. 제공받는 자의 이용 목적
        <br /> - 농업e지쌤 섭외를 위한 정보제공농업e지쌤 자격확인농업e지쌤 활동 확인
        <br /> 3. 제공받는 자의 보유 및 이용기간
        <br /> - 5년
        <br />
        <br />제6조(개인정보의 파기)
        <br />① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
        데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
        <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        <br />1. 파기절차
        <br />회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
        <br />2. 파기방법
        <br />회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록·저장된
        개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
        <br />
        <br />제7조(개인정보 자동수집장치 이용여부)
        <br />쿠키(Cookie)를 설치, 운영하고 있고 이용자는 이를 거부할 수 있습니다.
        <br />1. 쿠키란 무엇인가?
        <br />쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다.
        <br />2. 쿠키를 왜 사용하나?
        <br />쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. 이를 통해 이용자는 보다 손쉽게 서비스를
        이용할 수 있게 됩니다.
        <br />3. 쿠키를 수집하지 못하게 거부하고 싶다면?
        <br />이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의
        저장을 거부할 수도 있습니다. 다만 쿠키 설치를 거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
        <br />
        <br />제8조(개인정보의 안전성 확보조치)
        <br />회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
        <br />- 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등
        <br />- 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
        <br />- 물리적 조치 : 전산실, 자료보관실 등의 접근통제
        <br />
        <br />제9조(개인정보 보호책임자)
        <br />① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        <br />▶ 개인정보 보호책임자
        <br />- 성명 : 최필승
        <br />- 직책 : 대표
        <br />- 연락처 : ezssam@farmlearning.co.kr
        <br />※ 개인정보 보호 담당부서로 연결됩니다.
        <br />▶ 개인정보 보호 담당부서
        <br />- 부서명 : 사업개발부
        <br />- 담당자 : 김진만 부장
        <br />- 연락처 : hanmu1@farmlearning.co.kr
        <br />② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수
        있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
        <br />
        <br />제10조(개인정보 열람청구)
        <br />정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
        <br />▶ 개인정보 열람청구 접수·처리 부서
        <br />- 부서명 : 사업개발부
        <br />- 담당자 : 김진만 부장
        <br />- 연락처 : hanmu1@farmlearning.co.kr
        <br />
        <br />제11조(권익침해 구제방법)
        <br />정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
        <br />아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
        <br />▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
        <br />- 소관업무 : 개인정보 침해사실 신고, 상담 신청
        <br />- 홈페이지 : privacy.kisa.or.kr
        <br />- 전화 : (국번없이) 118
        <br />- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
        <br />▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
        <br />- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
        <br />- 홈페이지 : privacy.kisa.or.kr
        <br />- 전화 : (국번없이) 118
        <br />- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
        <br />▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
        <br />▶ 경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)
        <br />
        <br />제12조(개인정보 처리방침 변경)
        <br />① 이 개인정보 처리방침은 2024. 11. 20부터 적용됩니다.
        <br />
        <br />
        <span style="font-size: large; font-weight: bold;">b. 마케팅 정보 수신 동의</span>
        <br />마케팅 수신동의
        <br />1. 수집항목: 성명, 생년월일, 성별, 주소, 소속, 핸드폰(연락처), 전자우편주소
        <br />2. 이용목적: 농업e지 쌤 등록 및 사업 운영 사무, 농업e지 쌤 명단 관리, 농업인 대상 교육 진행 시 명단 제공, 이벤트 정보 등의 안내
        <br />3. 보유기간: 가입일로부터 5년

      </p>
    </div>
    <!-- E: 팝업 레이어: 이벤트 참여 개인정보 수집정책 -->
  </section>
</template>

<script>
import STUDENT from "@/api/student.js";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "LinkView7",
  components: {
    LoadingSpinner,
  },
  computed: {
  },
  data() {
    return {
      ezlogo: "/assets/images/ez_logo.png",
      description: '',
      isLoading: false,
      agreeAll: false, // "모두 동의합니다" 체크박스 상태
      agreePersonal: false, // 개인정보 동의 체크박스
      agreeMarketing: false, // 마케팅 동의 체크박스
      isPopupVisible: false, // 팝업 상태
    };
  },
  watch: {
    // "모두 동의합니다"가 체크되면 하위 항목들 체크
    agreeAll(newValue) {
      console.log("agreeAll", newValue);
      this.agreePersonal = newValue;
      this.agreeMarketing = newValue;
    },

    // 하위 항목들이 변경되면 "모두 동의합니다" 체크 상태 업데이트
    agreePersonal(newValue) {
      if (!newValue) {
        this.agreeAll = false;
      } else if (this.agreeMarketing) {
        this.agreeAll = true;
      }
    },

    agreeMarketing(newValue) {
      if (!newValue) {
        this.agreeAll = false;
      } else if (this.agreePersonal) {
        this.agreeAll = true;
      }
    }
  },
  mounted() { },
  methods: {
    showPopup() {
      this.isPopupVisible = true; // 팝업 표시
    },
    closePopup() {
      this.isPopupVisible = false; // 팝업 닫기
    },
    goBackward() {
      this.isLoading = true;
      this.$router.push("/link6");
    },
    async goForward() {
      try {
        let student = JSON.parse(localStorage.getItem("studentData"));
        console.debug("studentData", student);
        if (this.agreeAll) {
          student.agreeStatus = 'Y';
        } else {
          student.agreeStatus = 'N';
        }
        await STUDENT.insert(student);
        alert("교육생 정보가 성공적으로 저장되었습니다.");
        this.isLoading = true;
        this.$router.push("/link8");
      }
      catch (error) {
        console.error("교육생 정보 저장 실패:", error);
        alert("교육생 정보 저장 중 문제가 발생했습니다.");
      }
    },
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.back-btn {
  font-size: 1.5rem;
  color: #505050;
  border: none;
  cursor: pointer;
}

.header-logo {
  width: 100px;
  height: auto;
}

.step-label {
  background-color: #f95a25;
  color: white;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.step-label h1 {
  font-size: 1.2rem;
  margin: 0;
}

.text-center p {
  color: #6c757d;
}

.text-left {
  width: 100%;
  text-align: left;
  margin: 0;
}

.text-left p {
  text-align: left !important;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  color: #000000;
  font-size: 1rem;
}

.fs-1 {
  font-size: 1.5rem !important;
  color: #f95a25 !important;
}

.fs-2 {
  font-size: 1rem;
  margin-top: 20px;
}

strong {
  color: #000000;
  font-weight: bold;
}

.full-width-btn {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border-radius: 50px;
  margin-top: 15px;
  border: none;
  color: white;
}

.form-check-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.form-check-label {
  font-size: 1rem;
}

.center-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px auto;
  background-color: #ffffff;
  border-radius: 16px;
  max-width: 350px;
  box-sizing: border-box;
}

.container-fluid {
  padding: 10px 20px;
}

/* "모두 동의합니다"와 하위 항목 간의 여백 */
.mb-3 {
  margin-bottom: 1.5rem;
}

.btn-primary {
  background-color: #323232;
}

.btn-secondary {
  background-color: #b3b7ba;
}

/* 클릭 불가능한 버튼 상태 */
button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* 동의 체크란 */
.form-check-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.form-check-label {
  font-size: 1rem;
}

/* "모두 동의합니다"와 하위 항목 간의 여백 */
.mb-3 {
  margin-bottom: 1.5rem;
}

/* S: 팝업 레이어 */
.slide-popup {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 95%;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 1000;
  transition: bottom 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.slide-popup.visible {
  bottom: 0;
}

.popup-header {
  padding: 1em;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h5 {
  margin: 0;
  font-size: 1.2rem;
}

.close-button {
  font-size: 1.5rem;
  color: #505050;
  background-color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.popup-iframe {
  flex: 1;
  width: 100%;
  border: none;
}

.scrollable-content {
  max-height: 100%; /* 원하는 높이 설정 */
  overflow-y: auto;  /* 세로 스크롤 활성화 */
  padding: 20px 50px;   /* 양쪽 여백 50px */
  margin: 0;         /* p 태그 기본 margin 제거 */
  box-sizing: border-box; /* 패딩 포함하여 크기 계산 */
}
/* E: 팝업 레이어 */
</style>