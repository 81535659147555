<template>
  <div class="row g-1">
    <ag-grid-vue class="ag-theme-alpine"
      :columnDefs="columns"
      :rowData="dataSource"
      :autoSizeStrategy="autoSizeStrategy"
      @grid-ready="onGridReady"
      :defaultColDef="defaultColDef"
      :animateRows="true"
      :enableCellTextSelection="true"
      :serverSideDatasource="serverSideDatasource"
      :pagination="pagination"
      :paginationPageSize="paginationPageSize"
      :paginationPageSizeSelector="paginationPageSizeSelector"
      style="width: 100%; height: calc(100vh - 40vh);">
    </ag-grid-vue>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";

export default {
  name: "GridTemplate",
  components: {
    'ag-grid-vue': AgGridVue,
  },
  props: {
    columns: { type: Array },
    dataSource: { type: Array },
  },
  data() {
    return {
      gridApi: null,
      defaultColDef: {
        // flex: 1,
        resizable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
      },
      autoSizeStrategy: null,
      rowModelType: 'serverSide',
      serverSideDatasource: null,
      pagination: null,
      paginationPageSize: 10,
      paginationPageSizeSelector: [10, 20, 30, 40, 50],
    }
  },
  computed: {
  },
  created() {
    this.autoSizeStrategy = {
      type: 'fitGridWidth',
      defaultMinWidth: 30,
    };
  },
  mounted() {
  },
  methods: {
    onGridReady(params) {
      this.$emit('grid-ready', params); // 부모 컴포넌트에 이벤트 전달
      // this.gridApi = params.api;
    },
    onBtnExport() {
      this.gridApi.exportDataAsCsv();
    },
    // setDataSource(data) {
    //     this.dataSource = data;
    // }
    // async getScreens() {
    //     try {
    //         const param = {
    //             // start_dt: "20230901",
    //             // end_dt: "20231118",
    //         };
    //         const { data } = await HOUSE.getScreenList(param);
    //         this.dataSource = data;

    //         let namSet = new Set(data.map(r => r.nam));
    //         console.log("nam : ", namSet);

    //         let statusSet = new Set(data.map(r => r.status));
    //         console.log("status : ", statusSet);
    //     } catch (error) {
    //         alert(error.data);
    //         console.log("error: ", error);
    //     }
    // }
  }
}

</script>