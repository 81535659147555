<template>
    <div class="spinner-container">
      <div class="spinner" />
    </div>
  </template>

  <script>
  export default {};
  </script>

  <style scoped>
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10000;
    width: 100vw;
    height: 80%;
    background-color: rgba(0,0,0,0.2);
  }
  .spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #e0e0e0;
    border-bottom: 5px solid #fe9616;
    animation: spin 1s linear infinite;
    position: relative;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  </style>