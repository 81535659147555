import router from "@/router";
import store from "@/store";
// import axios from "axios";
// import AUTH from "@/api/auth.js";

// let isTokenRefresh = false;
// let refreshSubscribers = [];

// const onTokenRefreshed = () => {
// 	refreshSubscribers.forEach(callback => callback());
// 	isTokenRefresh = false;
// 	refreshSubscribers = [];
// };

// const addRefreshSubscriber = callback => {
// 	refreshSubscribers.push(callback);
// };

// function setToken(token) {
// 	store.commit("user/SET_TOKEN", token);
// }

function setInterceptors(instance) {
	instance.interceptors.request.use(
		async config => {
			config.headers = {
				"Content-Type": "application/json",
				// "Access-Control-Allow-Origin": "*"
			};
			const token = store.getters["user/getToken"];
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
      console.log("config", config);
			return config;
		},
		error => Promise.reject(error.response),
	);

	instance.interceptors.response.use(
		response => response,
		async error => {
			if (error.response?.status == 401) {
				// const token = store.getters["user/getToken"];
				// error.config.headers.Authorization = `Bearer ${token}`;
				// const response = await axios.request(error.config);
				// return response;
				await router.push("/");
				return Promise.reject(error);
			}
			return Promise.reject(error.response);
		},
	);
	return instance;
}

export { setInterceptors };
