import { axiosService } from "./index.js";

function getApiPath(endPoint = "") {
    const commonPath = "/api/house";
    if (endPoint != "") endPoint = `/${endPoint}`;
    return `${commonPath}${endPoint}`;
}

function getScreenList(params) {
    return axiosService.get(getApiPath("screens"), { params: params });
}

function getEcoList(params) {
    return axiosService.get(getApiPath("ecos"), { params: params });
}

function getSensorsList(params) {
    return axiosService.get(getApiPath("sensors"), { params: params });
}

function getOnoffList(params) {
    return axiosService.get(getApiPath("onoffs"), { params: params });
}

function getWeatherList(params) {
    return axiosService.get(getApiPath("weathers"), { params: params });
}

export default { getScreenList, getEcoList, getSensorsList, getOnoffList, getWeatherList };