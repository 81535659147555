import { axiosService } from "./index.js";

function getApiPath(endPoint) {
    const commonPath = "/api/auth";
    console.debug('getApiPath:', `${commonPath}/${endPoint}`);
    return `${commonPath}/${endPoint}`;
}

function login(data) {
    console.debug('login data:', data);
    return axiosService.post(getApiPath("signin"), data);
}

function signup(data) {
    console.debug('signup data:', data);
    return axiosService.post(getApiPath("signup"), data);
}

function logout() {
    return axiosService.post(getApiPath("logout"));
}

function reissue(data) {
    return axiosService.post(getApiPath("reissue"), data);
}

export default { login, signup, logout, reissue };