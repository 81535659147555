<template>
    <div :id="id">
        <apexchart type="line" :height="height" :options="chartOptions" :series="chartSeries"></apexchart>
    </div>
</template>

<script>
import { apexchart } from 'vue3-apexcharts';
import ApexCharts from 'apexcharts';

export default {
    name: "LineChart",
    extends: {
        apexchart
    },
    props: {
        id: { type: String, default: "lineChart" },
        height: { type: String },
        chartTitle: { type: String, default: "" }
    },
    computed: {
        // height() { return 'auto'; }
    },
    data() {
        return {
            chartSeries: [
                // {
                //     data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
                // },
            ],
            chartOptions: {
                chart: {
                    // height: 550,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                // series: [],
                // colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: this.chartTitle,
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                // xaxis: {
                //     // categories: [],//[2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
                // },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: '#2E93fA'
                        },
                        labels: {
                            style: {
                                colors: '#2E93fA'
                            }
                        },
                        title: {
                            text: "",
                            style: {
                                color: '#2E93fA'
                            }
                        }
                    },
                ],
                toolbar: {
                    export: {
                        csv: {
                            filename: "Temperature"
                        }
                    }
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    position: 'top'
                }
            },

        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
        },
        updateChart(chartSeries) {
            var chart = new ApexCharts(document.querySelector(`#${this.id}`), this.chartOptions);
            // console.log(chartSeries);
            this.chartSeries = chartSeries;

            chart.updateSeries(chartSeries, true);
            // chart.updateOptions({
            //     series: chartSeries,
            // });
            chart.render();
        }
    }
};
</script>