import { axiosService } from "./index.js";

function getApiPath(endPoint = "") {
  const commonPath = "/api/student";
  if (endPoint != "") endPoint = `/${endPoint}`;
  return `${commonPath}${endPoint}`;
}

function getList(params) {
  return axiosService.get(getApiPath("list"), { params: params });
}

function getDownload(params) {
  axiosService.get(getApiPath('download'), {
    responseType: 'blob',
    params: params
  }).then((response) => {
    const url
      = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
    const link = document.createElement('a');
    const now = new Date();
    const timestamp = now.getFullYear() + '-' +
                      ("0" + (now.getMonth() + 1)).slice(-2) + '-' +
                      ("0" + now.getDate()).slice(-2) + '_' +
                      ("0" + now.getHours()).slice(-2) + '-' +
                      ("0" + now.getMinutes()).slice(-2) + '-' +
                      ("0" + now.getSeconds()).slice(-2);
    link.href = url;
    link.setAttribute('download', `Student_${timestamp}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}

function insert(params) {
  return axiosService.post("/api/front/insert", params);
}

function update(params) {
  return axiosService.post(getApiPath("update"), params);
}

function deleteData(params) {
  return axiosService.post(getApiPath("deleteData"), params);
}

export default { getList, getDownload, insert, update, deleteData };