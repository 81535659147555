import { axiosService } from "./index.js";

function getApiPath(endPoint = "") {
  const commonPath = "/api/front";
  if (endPoint != "") endPoint = `/${endPoint}`;
  return `${commonPath}${endPoint}`;
}

function check(params) {
  return axiosService.get(getApiPath("check"), { params: params });
}

export default { check };