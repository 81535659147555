<template>
    <section class="vh-100 d-flex flex-column align-items-center justify-content-center">
      <div class="header">
        <!-- 로고 -->
        <img :src="ezlogo" class="header-logo" alt="농업e지 로고" />
      </div>
      <label class="col-12 col-form-label-sm text-center fs-5 text-muted" for="form3Example3" style="font-weight: 350;">
차세대 농업농촌통합정보시스템
          </label>

      
      <!-- 수료 메시지 -->
      <div class="completion-message text-center mt-4">
        <h2 class="text-orange">수료를
            <br />축하드립니다</h2>
      </div>
      
      <!-- 캐릭터 이미지 -->
      <div class="image-container text-center mt-4">
        <img :src="ezCharacter" class="character-img" alt="농업e지 캐릭터" />
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "CompletionPage",
    data() {
      return {
        ezlogo: "/assets/images/ez_logo.png", // 로고 이미지
        ezCharacter: "/assets/images/ez_character.png", // 캐릭터 이미지
      };
    }
  };
  </script>
  
  <style scoped>
  /* 전체 레이아웃 */
  .vh-100 {
    height: 100vh;
  }
  
  /* 상단 로고 */
  .header-logo {
    width: 200px;
    height: auto;
  }
  
  /* 수료 메시지 */
  .completion-message h2 {
    font-size: 2.5rem;
    color: #f95a25; /* 주황색 */
    margin: 0;
  }
  
  /* 캐릭터 이미지 */
  .character-img {
    width: 250px; /* 적당한 크기로 조정 */
    height: auto;
  }
  
  /* 텍스트 및 이미지 정렬 */
  .text-center {
    text-align: center;
  }
  
  /* 여백 설정 */
  .mt-4 {
    margin-top: 4rem;
  }
  </style>