<template>
  <button @click="onClick" class="btn btn-outline-primary btn-sm">{{ params.label || label }}</button>
</template>

<script>
export default {
  name: 'ButtonRenderer',
  props: {
    params: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: '클릭1'
    }
  },
  methods: {
    onClick() {
      if (this.params && typeof this.params.onClick === 'function') {
        this.params.onClick();
      }
    }
  }
}
</script>

<style scoped>
.btn {
  padding: 0.1rem 0.5rem;
  font-size: 0.5rem;
  line-height: 1;
}
</style>
