<template>
  <div class="container-fluid">
    <div class="row">

      <div class="col-md">
        <div class="fs-2 fw-bold"> 기상청 예보 </div>
      </div>

      <div class="col-md">
        <div class="row justify-content-end">
          <div class="col text-end">
            <div class="input-group mb-3">
              <input type="date" class="form-control" placeholder="검색 시작일" aria-label="start_dt" v-model="startDt"/>
              <span class="input-group-text">~</span>
              <input type="date" class="form-control" placeholder="검색 종료일" aria-label="end_dt" v-model="endDt" />
              <button type="button" class="btn btn-primary" @click="getDatas">검색</button>
              <button type="button" class="btn btn-outline-secondary" @click="resetDatas"><font-awesome-icon icon="fa-solid fa-eraser"/></button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <LoadingSpinner v-if="isLoading"></LoadingSpinner>
      <GridTemplate ref="grid" :columns="columnDefs" :dataSource="dataSource"></GridTemplate>
    </div>
  </div>
</template>

<script>
import HOUSE from "@/api/house.js";
import { KMA_WEATHER_COLUMN } from "@/plugins/grid-column.js";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "KmaWeatherView",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      gridApi: null,
      columnDefs: KMA_WEATHER_COLUMN,
      dataSource: [],
      defaultColDef: {
        // flex: 1,
        resizable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
      },
      startDt: '',
      endDt: '',
      isLoading: false,
    }
  },
  created() {
    // 기상예보는 3일뒤가 기본값
    let date = new Date();
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000));
    date.setDate(date.getDate() + 3);
    this.endDt = date.toISOString().split('T')[0]
    this.getDatas();
  },
  mounted() {
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    onBtnExport() {
      this.gridApi.exportDataAsCsv();
    },
    async getDatas() {
      try {
        let { startDt, endDt, } = this;
        if (startDt) {
          startDt = startDt + 'T00:00:00';
        }
        if (endDt) {
          endDt = endDt + 'T23:59:59';
        }
        const param = {
          start_dt: startDt,
          end_dt: endDt,
        };
        console.debug('param: ', param);
        this.isLoading = true;
        const { data } = await HOUSE.getWeatherList(param);
        let dataSource = data.sort((a, b) => {
          return new Date(b.meaDat) - new Date(a.meaDat);
        });
        console.log('dataSource: ', dataSource);
        this.dataSource = dataSource;
      } catch (error) {
        alert(error.data);
        console.log("error: ", error);
      }
      this.isLoading = false;
    },
    resetDatas() {
      this.startDt = '';
      this.endDt = '';
    }
  }
}
</script>

<style></style>