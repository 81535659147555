import { createApp } from 'vue';

import App from "./App.vue";
import router from "./router";
import eventBus from "./plugins/event-bus.js";
import commonjs from "./plugins/common.js";
// import templatejs from "./plugins/template.js";
import store from "./store";

// Bootstrap import
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

// fontawesome import
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas, far, fab);

import VueApexCharts from "vue3-apexcharts";

import GridTemplate from "@/components/GridTemplate.vue";
import LineChart from "@/components/LineChart.vue";
import ColumnChart from "@/components/ColumnChart.vue";

const app = createApp(App);
app.use(datePicker);
app.use(router);
app.use(eventBus);
app.use(commonjs);
// app.use(templatejs);
app.use(store);
app.use(VueApexCharts);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('GridTemplate', GridTemplate);
app.component('LineChart', LineChart);
app.component('ColumnChart', ColumnChart);
app.mount('#app');
